import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilialQuota, IQuota, IQuotaRelationDTO, IQuotasData } from './types';

interface IQuotas {
  quotas: IQuota[];
  filialQuota?: IFilialQuota;
  totalQuotas: number;
  quotasFilters: {
    Count: number;
    Offset: number;
    Query: '';
    FilterConditions: [];
  };
  // Relation
  selectedQuota: IQuota | null;
  quotaRelations: IQuotaRelationDTO[];
  totalQuotaRelations: number;
  quotaRelationFilters: {
    Count: number;
    Offset: number;
    Query: string;
    FilterConditions: any[];
  };
  selectedQuotaRelation: IQuotaRelationDTO | null;
}

const initialState: IQuotas = {
  quotas: [],
  filialQuota: {} as IFilialQuota,
  totalQuotas: 0,
  quotasFilters: {
    Count: 25,
    Offset: 0,
    Query: '',
    FilterConditions: []
  },
  selectedQuota: null,
  // Relation
  quotaRelations: [],
  totalQuotaRelations: 0,
  quotaRelationFilters: {
    Count: 25,
    Offset: 0,
    Query: '',
    FilterConditions: []
  },
  selectedQuotaRelation: null
};

export const quotasSlice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    setQuotas(state, action: PayloadAction<IQuotasData>) {
      state.quotas = action.payload.Quotas;
      state.totalQuotas = action.payload.TotalCount;
    },
    setFilialQuota(state, action: PayloadAction<IFilialQuota>) {
      state.filialQuota = action.payload;
    },
    setQuotasFilters(state, action) {
      state.quotasFilters = action.payload;
    },
    setSelectedQuota(state, action: PayloadAction<IQuota | null>) {
      state.selectedQuota = action.payload;
    },
    // Relations
    setQuotaRelations(
      state,
      action: PayloadAction<{ Items: IQuotaRelationDTO[]; TotalCount: number }>
    ) {
      state.quotaRelations = action.payload.Items;
      state.totalQuotaRelations = action.payload.TotalCount;
    },
    setQuotaRelationFilters(state, action) {
      state.quotaRelationFilters = action.payload;
    },
    setSelectedQuotaRelation(state, action: PayloadAction<IQuotaRelationDTO | null>) {
      state.selectedQuotaRelation = action.payload;
    }
  }
});

export const quotasActions = quotasSlice.actions;
export const quotasReducer = quotasSlice.reducer;
