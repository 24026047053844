import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  avatarBox: {
    position: 'relative',
    width: '100px',
    height: '100px',

    // [utils.largerThan('xs')]: {
    //   width: '124px',
    //   height: '124px'
    // }

    '&:hover': {
      '& .mantine-Overlay-root': {
        display: 'flex'
      }
    }
  },
  avatarOverlay: {
    display: 'none',
    backgroundColor: '#000000',
    opacity: 0.6,
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 1
  },

  overlayText: {
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
    color: '#ffffff',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  headerInput: {
    '& .mantine-TextInput-input': {
      padding: '0',
      border: 'none',
      borderRadius: '0px',
      borderBottom: `1px solid ${theme.colors.backgroundGray[5]}`
    }
  }
}));
