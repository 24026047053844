import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  group: {
    cursor: 'pointer'
  },
  groupTitle: {
    fontSize: '12px',
    color: theme.colors.blue[6]
  },

  chevron: {
    transition: 'transform 200ms ease',
    color: theme.colors.blue[6]
  },

  sectionTitle: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#4B4B4B'
  },

  selectLabel: {
    '& .mantine-InputWrapper-label': {
      color: '#667085',
      fontSize: '12px',
      fontWeight: 100
    }
  }
}));
