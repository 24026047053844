import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingTop: '20px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
  },

  info: {
    display: 'flex',
    flexDirection: 'column'
  },

  dataContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  label: {
    textTransform: 'uppercase',
    color: '#868E96',
    fontWeight: '500',
    width: '60px'
  },

  tip: {
    fontSize: '14px',
    lineHeight: '204%'
  },

  description: {
    maxWidth: '350px',
    fontSize: '14px',
    paddingLeft: '12px',
    '@media (max-width: 574px)': {
      maxWidth: '200px'
    }
  },

  passwordInput: {
    maxWidth: '350px',
    '@media (max-width: 574px)': {
      maxWidth: '280px'
    },
    '& .mantine-PasswordInput-input': {
      fontSize: '14px',
      border: 'none'
    },
    '[type="password"]': {
      letterSpacing: '2px'
    }
  },

  copyIcon: {
    cursor: 'pointer'
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',
    '&:hover': {
      backgroundColor: '#eff0f4'
    }
  },

  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    marginRight: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    border: 'none',
    textTransform: 'inherit',
    height: '28px',
    fontSize: '14px',
    padding: theme.spacing.xs,
    fontWeight: 400
  },

  roleBox: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}));
