import { ILinkedEmail } from '@/entities/admin-app/email';
import { themeColors } from '@/theme/colors';
import { Button, SimpleGrid, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { LinkEmailsModals } from '@/containers/pages/user/components/sections/linked-emails/components/LinkEmailsModals';
import { useLinkedEmail } from '@hooks/users/useLinkedEmail';
import { EmailItem } from '@/containers/pages/user/components/sections/linked-emails/components/EmailItem';

export interface ILinkedEmailsProps {
  form: UseFormReturnType<any>;
  sectionFormKey: string;
  listLinkedEmails: ILinkedEmail[] | null;
  isCreateUserPage?: boolean;
  filialId?: number | null;
  currentId?: number;
  editUser: boolean;
}

export const LinkedListEmails = (props: ILinkedEmailsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { form, sectionFormKey, filialId, currentId, editUser } = props;

  const {
    closePasswordModal,
    closeDeleteModal,
    deleteEmailItem,
    changePassword,
    handleAddEmail,
    openCreateModal,
    closeCreateModal,
    open,
    opened,
    close,
    isLoading,
    isOpenedDeleteEmailModal,
    isOpenedChangePasswordModal,
    isSuccess,
    selectedEmail,
    isOpenedCreateModal,
    visibleCreateButton,
    listEmails
  } = useLinkedEmail(props);

  return (
    <>
      <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
        <Button
          className={classes.addBtn}
          size="sm"
          variant="outline"
          rightSection={<IconPlus color={themeColors.brandGray[0]} />}
          onClick={open}
        >
          {t('profile.linkAccount')}
        </Button>
      </SimpleGrid>
      {visibleCreateButton && (
        <SimpleGrid cols={{ base: 1, xs: 2 }} mt={16} w="100%">
          <Button
            className={classes.addBtn}
            size="sm"
            variant="outline"
            disabled={!filialId}
            rightSection={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={openCreateModal}
          >
            {t('profile.createNewAccount')}
          </Button>
        </SimpleGrid>
      )}
      <Text mt={16} fz={14} c="#667085">
        {t('profile.infoDescription')}
      </Text>
      {listEmails.length !== 0 && (
        <Text className={classes.accountName} fw="600" mt={16} fz={16} c="#000">
          {t('profile.accounts')}
        </Text>
      )}
      <div className={classes.listWrapper}>
        {listEmails.map((email, index) => (
          <EmailItem
            email={email}
            key={index}
            deleteEmailItem={deleteEmailItem}
            changePassword={changePassword}
          />
        ))}
      </div>
      <LinkEmailsModals
        isOpenedCreateModal={isOpenedCreateModal}
        filialId={filialId}
        editUser={editUser}
        isLoading={isLoading}
        isSuccess={isSuccess}
        close={close}
        selectedEmail={selectedEmail}
        opened={opened}
        isOpenedChangePasswordModal={isOpenedChangePasswordModal}
        isOpenedDeleteEmailModal={isOpenedDeleteEmailModal}
        currentId={currentId}
        form={form}
        sectionFormKey={sectionFormKey}
        closeCreateModal={closeCreateModal}
        closeDeleteModal={closeDeleteModal}
        closePasswordModal={closePasswordModal}
        handleAddEmail={handleAddEmail}
      />
    </>
  );
};
