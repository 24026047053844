import { Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconUsers } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useGetUsersQuery } from '@/entities/admin-app/users/api';
import { CreateUserModal } from '@/containers/pages/user/feature/create-user';
import { useDisclosure } from '@mantine/hooks';

interface IUsersSectionProps {
  filialId?: number;
  subscriptionPage?: boolean;
}

export const UsersSection: FC<IUsersSectionProps> = ({ filialId, subscriptionPage }) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  const handleCreateUser = () => {
    open();
  };

  const { data: usersData } = useGetUsersQuery({
    Count: 20,
    Offset: 0,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: '',
    FilterConditions: []
  });

  const usersMenu: IListItemProps = {
    withCheck: false,
    items: [
      {
        name: t('users.list'),
        link: ROUTES.users.path,
        badge: usersData && usersData.TotalCount
      },
      { name: t('filials.modules.addUser'), openModal: handleCreateUser },
      {
        name: t('filials.linkInvite'),
        link: `${ROUTES.filials.edit.clearPath}/${filialId}/${ROUTES.filials.edit.pages.auth.invite}`
      }
    ]
  };

  return (
    <>
      <CardContainer
        sectionName={t('navigation.users')}
        Icon={IconUsers}
        subscriptionPage={subscriptionPage}
      >
        <Stack gap="11px" sx={{ width: '100%' }}>
          <ListItem items={usersMenu.items} withCheck={usersMenu.withCheck} />
        </Stack>
      </CardContainer>
      {opened && (
        <CreateUserModal
          opened={opened}
          modalClose={() => {
            close();
          }}
        />
      )}
    </>
  );
};
