import { Box, Text, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { DriveInfoPanelsEnum } from '@/types/enums/modules';
import { useState } from 'react';
import { MassAddQuotaForm } from '../mass-quota-modal';

export const MassAddQuota = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <Box className={classes.section} data-section={DriveInfoPanelsEnum.quotas}>
      <Text className={classes.title}>{t('filials.diskSpace.massAddQuota')}</Text>
      <Text fz="14" c="#667085">
        {t('filials.diskSpace.massQuotaRule')}
      </Text>
      <Button
        size="sm"
        mt="sm"
        radius={8}
        color="green"
        px="lg"
        onClick={() => {
          setIsModalOpened(true);
        }}
      >
        {t('filials.diskSpace.assignQuotas')}
      </Button>

      <MassAddQuotaForm isOpened={isModalOpened} setIsModalOpened={setIsModalOpened} />
    </Box>
  );
};
