import { Flex, Loader, Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconMail } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useGetEmailServerQuery } from '@/entities/admin-app/email/api';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { MailServerForm } from '@/containers/pages/filials/edit/components/tabs/mail-servers/components/serverForm';

interface IMailSectionProps {
  filialId?: number;
}

export const MailSection: FC<IMailSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: serversList, isLoading: serversLoading } = useGetEmailServerQuery({
    Id: filialId || 0
  });

  const handleCreateServer = () => {
    setIsModalOpen(true);
  };

  const safetyMenu: IListItemProps = {
    withCheck: false,
    items: [
      {
        name: t('filials.serverList'),
        link: `${ROUTES.filials.edit.clearPath}/${filialId}/${ROUTES.filials.edit.pages.common.mailServers}`,
        badge: serversList && serversList?.length
      },
      {
        name: t('filials.addServer'),
        openModal: handleCreateServer
      },
      ...(!isSaaS(Settings)
        ? [
            {
              name: t('filials.emailSetting'),
              link: `${ROUTES.filials.edit.clearPath}/${filialId}/${ROUTES.filials.edit.pages.common.mailingSetup}`
            }
          ]
        : [])
    ]
  };

  return (
    <>
      <CardContainer sectionName={t('servers.title')} Icon={IconMail}>
        {serversLoading ? (
          <Flex w="100%" align="center" justify="center">
            <Loader size="sm" />
          </Flex>
        ) : (
          <Stack gap="11px" sx={{ width: '100%' }}>
            <ListItem items={safetyMenu.items} withCheck={safetyMenu.withCheck} />
          </Stack>
        )}
      </CardContainer>
      {isModalOpen && (
        <MailServerForm
          CustomerId={filialId || 0}
          opened={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          isEditing={false}
        />
      )}
    </>
  );
};
