import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',
    '&:hover': {
      backgroundColor: '#eff0f4'
    },
    '&:active': {
      transform: `translateY(1px)`
    }
  },

  textField: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  }
}));
