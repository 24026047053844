import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { customBaseQuery } from '@/utils/customBaseQuery';
import {
  ICommonSettings,
  IProductNotification,
  IProductNotificationData,
  IUpdateCommonSettings,
  IUploadedFileResponse
} from './types';
import { IFullResponse } from '@/types/api';
const tagTypes = ['ProductNotification'];
export const commonSettingsApi = createApi({
  reducerPath: 'api/commonSettings',
  baseQuery: customBaseQuery(`${BASE_URL}`),
  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    commonSettings: build.query<ICommonSettings, void>({
      query: () => ({
        url: 'v1/settings/common'
      })
      // transformResponse: (response: IFullResponse<ICommonSettings>) => response.Response.Data
    }),

    changeCommonSettings: build.mutation<void, IUpdateCommonSettings>({
      query: (payload?: IUpdateCommonSettings) => ({
        url: `v1/settings/common`,
        body: {
          ...payload
        },
        method: 'post'
      })
    }),

    uploadFile: build.mutation<IUploadedFileResponse, FormData>({
      query: (payload: FormData) => ({
        url: `v1/Files/Upload`,
        body: payload,
        method: 'post'
      })
    }),

    syncTeamSettings: build.query<void, void>({
      query: () => ({
        url: 'v2/userprofile/StartExportUser'
      })
    }),
    getProductNotification: build.query<IProductNotification, void>({
      query: () => ({
        url: 'v1/productnotification'
      }),
      transformResponse: (response: IFullResponse<IProductNotificationData>) => ({
        ...response.Response.Data?.UpdateInfo
      }),
      providesTags: ['ProductNotification']
    }),
    updateProductNotification: build.mutation<IProductNotification, IProductNotificationData>({
      query: (payload: IProductNotificationData) => ({
        url: `v1/productnotification`,
        body: payload,
        method: 'post'
      })
    }),
    getTimezones: build.query<any, void>({
      query: () => ({
        url: 'v1/calendars/timezones'
      })
    })
  })
});

export const {
  useCommonSettingsQuery,
  useChangeCommonSettingsMutation,
  useLazyCommonSettingsQuery,
  useLazySyncTeamSettingsQuery,
  useUploadFileMutation,
  useGetProductNotificationQuery,
  useUpdateProductNotificationMutation,
  useGetTimezonesQuery,
  useLazyGetTimezonesQuery
} = commonSettingsApi;
