import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@/components/header/constants';

export const useStyles = createStyles((_theme, _, utils) => ({
  homeContainer: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: '#f6f7f9',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    overflow: 'scroll',
    padding: '16px'
  },

  homeColumns: {
    columnCount: 4,
    columnGap: '8px',
    [utils.largerThan('xl')]: {
      maxWidth: '1504px'
    },
    [utils.smallerThan('xl')]: {
      columnCount: 4
    },
    [utils.smallerThan('lg')]: {
      columnCount: 3
    },
    [utils.smallerThan('md')]: {
      columnCount: 2
    },
    [utils.smallerThan('xs')]: {
      columnCount: 1
    },
    '& [data-name="card-container"]': {
      breakInside: 'avoid',
      marginBottom: '8px'
    }
  }
}));
