import { modalTheme } from '@/components/modal/styles';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  // modalContainer: {
  //   width: '100%',
  //   height: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   background: 'white'
  // },

  input: {
    '& .mantine-TextInput-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    }
  },

  radio: {
    padding: '5px 0',

    '& .mantine-Radio-inner': {
      alignSelf: 'center'
    },

    '& .mantine-Radio-label': {
      fontSize: '14px',
      paddingLeft: theme.spacing.xs
    }
  },

  numberInput: {
    '&  .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-NumberInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },

  select: {
    '&  .mantine-TextInput-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      fontWeight: 400
    }
  }
}));
