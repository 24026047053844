import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ActionIcon, Box } from '@mantine/core';
import { PanelsEnum } from '@/types/enums/roles';
import { EditIcon } from '@assets/icons';
import { RolesInfoMainContext } from '../../panels';
import { useStyles } from './styles';
import { Action } from '../../../Table/action';
import { IconArrowLeft } from '@tabler/icons';
import { useActions } from '@/hooks/redux/action';
import { canEditRole } from '@/lib/utils/access';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/containers/routes/constants';

export const DrawerHeader: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);
  const { setSelectedRolesId, setSelectedPanel } = useActions();
  const [isLoading, setIsLoading] = useState(true);

  const Type = rolesInfoCommon?.Type;
  const goFilialUser = () => {
    setSelectedPanel(PanelsEnum.editInformation);
  };

  const goBack = useCallback(() => {
    setSelectedRolesId(0);
    navigate(ROUTES.roles.path);
  }, []);

  useEffect(() => {
    if (rolesInfoCommon) {
      setIsLoading(false);
    }
  }, [rolesInfoCommon]);

  return (
    <>
      <ActionIcon onClick={goBack} className={classes.backArrow}>
        <IconArrowLeft color="#667085" />
      </ActionIcon>
      <Box className={classes.action}>
        {isLoading ? null : (
          <>
            <ActionIcon size="md" onClick={goFilialUser}>
              <EditIcon />
            </ActionIcon>
            {canEditRole(Type) ? <Action item={rolesInfoCommon} /> : null}
          </>
        )}
      </Box>
    </>
  );
};
