import {
  IActiveConnection,
  IUserInfo,
  IUserInfoCommon,
  IUserQuota,
  IUserStorage,
  IUserTypeEnum
} from '@/entities/admin-app/user';
import { UserStatusEnum } from '@/types/enums/user';

export const generalInfoInit: IUserInfoCommon['GeneralInformation'] = {
  FirstName: '',
  Surname: '',
  MiddleName: '',
  Email: '',
  MobilePhone: '',
  Login: '',
  Avatar: '',
  Status: UserStatusEnum.Ban,
  InternetAddress: '',
  // default select filial with Id: 1`
  Customer: { Id: 1 },
  UserType: IUserTypeEnum.Contact,
  ResetPasswordUrl: '',
  Positions: [],
  LdapIdentifier: null,
  Roles: [],
  UserKind: 0
};

export const credentialsInit: IUserInfo['Credentials'] = {
  Email: '',
  Login: '',
  Password: null,
  PasswordResetUrl: '',
  AdditionalEmail: null,
  isCreateEmail: true
};

export const commonInit: IUserInfo['Common'] = {
  FirstName: '',
  SurName: '',
  MiddleName: '',
  Gender: 0,
  BirthDate: null,
  Address: '',
  Phone: '',
  AdditionalPhone: '',
  LastEntryDate: '',
  DateOfCreate: new Date()
};

export const initForm: IUserInfo = {
  Id: 0,
  Avatar: '',
  AvatarId: null,
  Status: -2,
  Credentials: credentialsInit,
  Common: commonInit,
  // default select filial with Id: 1`
  Customer: { Id: 0 },
  Positions: [],
  Roles: [],
  LdapIdentifier: null,
  Connections: [] as IActiveConnection[],
  LinkedEmail: [],
  Quota: { Id: 0, Name: '' } as IUserQuota,
  StorageData: {} as IUserStorage,
  TimeZone: null,
  Language: null
};
