import { useEffect, Dispatch, FC, SetStateAction, useCallback, useState, useMemo } from 'react';
import { Drawer } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@/components/simple-table/types';
import { Panels } from './panels';
import { PanelsEnum } from '@/types/enums/roles';
import { useAppSelector } from '@/hooks/redux/redux';
import { useActions } from '@/hooks/redux/action';
import { useNavigate, useParams } from 'react-router-dom';

interface IRolesInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const RolesInfoMain: FC<IRolesInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const { setSelectedRolesId } = useActions();
  const { selectedRolesId } = useAppSelector((state) => state.roles);
  const navigate = useNavigate();
  const { id } = useParams();
  const toNumberId = useMemo(() => Number(id), [id]);
  const [isOpened, setIsOpened] = useState(false);
  const { setSelectedPanel } = useActions();

  useEffect(() => {
    if (toNumberId) {
      setSelectedRolesId(Number(toNumberId));
      setIsOpened(true);
    } else {
      handleClose();
    }
  }, [toNumberId]);

  const handleClose = useCallback(() => {
    setIsOpened(false);
    setSelectedRolesId(0);
    navigate('/roles');
    setSelectedPanel(PanelsEnum.generalInformation);
  }, []);

  return (
    <Drawer
      zIndex={1100}
      opened={isOpened}
      trapFocus={false}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {Boolean(selectedRolesId) && (
        <Panels
          selectedRolesId={selectedRolesId}
          setRowData={setRowData}
          handleClose={handleClose}
        />
      )}
    </Drawer>
  );
};
