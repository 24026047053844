import { ROUTES } from '@/containers/routes/constants';
import { ITransformedRoute } from '@/types';

export enum SettingsEnum {
  UserIp = 1,
  AdminIp = 2,
  SessionDuration = 3,
  PassEnterCount = 4,
  Ldap = 5,
  Smtp = 6,
  PasswordSecurity = 7,
  QuotaRules = 8
}

export function setToEndOFDate(date: Date): string {
  return new Date(date.setHours(23, 59, 59, 999)).toISOString();
}

export const limitsLengthFilialFields = {
  Title: 128,
  Company: 30
} as const;

export const getFilialEditRoutes = (): ITransformedRoute[] => {
  const { fullPath, pages } = ROUTES.filials.edit;
  return Object.values(pages)
    .map((page) => {
      return Object.values(page).map((route) => ({
        path: `${fullPath}/${route}`,
        chunk: route
      }));
    })
    .flat();
};
