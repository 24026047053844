import { createStyles } from '@mantine/emotion';
import { HEADER_HEIGHT } from '@components/header/constants';

export const useStyles = createStyles((theme, _, utils) => ({
  container: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  list: {
    width: 'calc(100vw - 264px)'
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  searchWrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 24px',
    width: '100%',
    margin: '24px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [utils.largerThan('xs')]: {
      alignItems: 'center'
    }
  },

  filtersListWrapper: {
    width: `calc(100% - 48px)`,
    padding: `${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.sm}`,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .mantine-Menu-dropdown': {
      padding: theme.spacing.sm
    }
  },

  createButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: theme.spacing.xs,
    span: {
      height: 'auto',
      marginRight: '8px',
      div: {
        margin: '0'
      }
    },
    [utils.smallerThan('lg')]: {
      padding: '15px',
      justifyContent: 'center',
      span: {
        margin: '0'
      }
    }
  },

  search: {
    marginRight: '27px',
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    },
    '& .mantine-Input-section': {
      marginLeft: '15px'
    },
    [utils.smallerThan('xs')]: {
      marginBottom: 15
    }
  },

  avatar: {
    cursor: 'pointer'
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));
