import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  filterHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F4F7',
    padding: '4px 20px 4px 20px',
    height: '32px',
    gap: 0,
    borderRadius: '54px',
    fontWeight: 400,
    color: 'var(--mantine-colors-black)'
  },

  filterItem: {
    flexWrap: 'nowrap'
  },

  menuButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  badgeContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '12px'
  },

  scroll: {
    overflow: 'scroll',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    visibility: 'hidden'
  },

  checkbox: {
    display: 'flex',
    marginRight: theme.spacing.md,
    '& input': {
      cursor: 'pointer'
    }
  },
  badge: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '16px',
    padding: `0px 6px`
  },
  leftSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 20
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end'
  },

  menu: {
    height: '180px',
    marginBottom: '10px'
  },

  backArrow: {
    display: 'none',
    '@media (max-width: 574px)': {
      display: 'flex'
    }
  },

  dropdown: {
    padding: theme.spacing.sm
  }
}));
