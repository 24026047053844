import { FC, PropsWithChildren } from 'react';
import { ActionIcon, Badge, Box, Popover, UnstyledButton } from '@mantine/core';
import { IconBackspace, IconChevronDown } from '@tabler/icons';
import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { themeColors } from '@/theme/colors';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';

interface IFilterBadgeProps extends PropsWithChildren {
  filterMenu: IFilterMenu;
  handleFilterReset: (value: IFiltersEnumType) => void;
  activeIds: number[];
  filterType: IFiltersEnumType;
}

export const FilterBadge: FC<IFilterBadgeProps> = ({
  filterMenu,
  handleFilterReset,
  activeIds,
  filterType,
  children
}) => {
  const { classes } = useStyles();
  const isMinHeight = useMediaQuery('(max-height: 515px)');

  const onHandleFilterReset = (e: React.MouseEvent, type: IFiltersEnumType) => {
    e.stopPropagation();
    handleFilterReset(type);
  };

  return (
    <Popover width="auto" zIndex={1400} position={isMinHeight ? 'right' : 'bottom-start'}>
      <Popover.Target>
        <Badge
          variant="transparent"
          color="gray"
          className={classes.filterHeader}
          rightSection={
            activeIds?.length > 0 && (
              <Box className={classes.badgeContainer}>
                <Badge
                  className={
                    filterType === IFiltersEnumType.DateOfCreateFilter
                      ? classes.withoutNumber
                      : classes.badge
                  }
                  variant="filled"
                >
                  {filterType === IFiltersEnumType.DateOfCreateFilter ? ' ' : activeIds.length}
                </Badge>

                <ActionIcon size={24} onClick={(e) => onHandleFilterReset(e, filterMenu.Type)}>
                  <IconBackspace size={16} color={themeColors.brandGray[0]} />
                </ActionIcon>
              </Box>
            )
          }
        >
          <UnstyledButton className={classes.menuButton}>
            {filterMenu?.label} <IconChevronDown size={18} />
          </UnstyledButton>
        </Badge>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdownContainer}>{children}</Popover.Dropdown>
    </Popover>
  );
};
