import { useState } from 'react';
import { ActionIcon, Badge, Box, Button, SimpleGrid } from '@mantine/core';
import { z } from 'zod';
import { TextFieldInput } from '@components/fields/text-field-input';
import { useStyles } from './styles';
import { IconPlus, IconX, IconCircleX } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { IUserTableData } from '@/entities/admin-app/users';
import { SelectUserModal } from '@components/modal/select-user';
import { UseFormReturnType } from '@mantine/form';
import { IUserPosition } from '@/entities/admin-app/user';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

interface IPositionsProps {
  form: UseFormReturnType<any>;
  sectionFormKey: string;
  Positions: IUserPosition[] | null;
  isLimitedAccess?: boolean;
  disabled?: boolean;
}
export const Positions = ({
  form,
  sectionFormKey,
  Positions,
  isLimitedAccess,
  disabled
}: IPositionsProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useStyles();
  const selectedUser = {} as IUserTableData;
  const [selectOwnerModal, setSelectOwnerModal] = useState<boolean>(false);
  const [selectedPositionId, setSelectPositionId] = useState<number | null>(null);

  const isMobile = useMediaQuery('(max-width: 576px)');

  const closeSelectOwnerModal = () => {
    setSelectOwnerModal(false);
    setSelectPositionId(null);
  };

  const openSelectOwnerModal = (index: number) => {
    setSelectPositionId(index);
    setSelectOwnerModal(true);
  };

  const handleChangePositionParent = (parent: IUserTableData) => {
    if (selectedPositionId !== null && form) {
      form?.setFieldValue(`${sectionFormKey}.${selectedPositionId}.Director`, {
        Id: parent.Id,
        Name: parent.Name
      });
    }

    closeSelectOwnerModal();
  };

  return (
    <>
      {Positions?.length ? (
        Positions?.map((position, index) => {
          return (
            <SimpleGrid
              w="100%"
              key={index}
              cols={{ base: 1, xs: 2 }}
              mt={isMobile ? theme.spacing.sm : 0}
              verticalSpacing="sm"
            >
              <TextFieldInput
                disabled={disabled || isLimitedAccess}
                fieldName={`${sectionFormKey}.${index}.Position.Name`}
                label={t('profile.position')}
                form={form}
                withAsterisk
                dataAttribute={`${sectionFormKey}.${index}.Position.Name`}
              />
              <Box className={classes.addManagerContainer} mt={!isMobile ? '24px' : 0}>
                {position.Director ? (
                  <Badge
                    className={classes.badge}
                    variant="outline"
                    data-cy={`${position.Director.Name}`}
                    rightSection={
                      <>
                        {isLimitedAccess ? null : (
                          <ActionIcon
                            size={24}
                            variant="transparent"
                            onClick={async () => {
                              const prevErrors = form.errors;
                              await form.setFieldValue(`${sectionFormKey}.${index}`, {
                                Position: position.Position,
                                Director: null
                              });
                              await form.setErrors(prevErrors);
                            }}
                          >
                            <IconX size={16} color={themeColors.brandGray[0]} />
                          </ActionIcon>
                        )}
                      </>
                    }
                  >
                    {position.Director.Name}
                  </Badge>
                ) : isLimitedAccess ? null : (
                  <Button
                    className={classes.addBtn}
                    size="sm"
                    variant="outline"
                    rightSection={<IconPlus color={themeColors.brandGray[0]} />}
                    onClick={() => openSelectOwnerModal(index)}
                    data-cy="addManagerBtn"
                    disabled={disabled}
                  >
                    {t('profile.addManager')}
                  </Button>
                )}
                {isLimitedAccess ? null : (
                  <ActionIcon
                    ml={10}
                    size={30}
                    onClick={() => form.removeListItem(`${sectionFormKey}`, index)}
                  >
                    <IconCircleX color="#667085" />
                  </ActionIcon>
                )}
              </Box>
            </SimpleGrid>
          );
        })
      ) : isLimitedAccess ? (
        <Box>{t('profile.noPosition')}</Box>
      ) : null}

      {isLimitedAccess ? null : (
        <SimpleGrid cols={{ base: 1, xs: 2 }} mt={16} w="100%">
          <Button
            className={classes.addBtn}
            size="sm"
            variant="outline"
            rightSection={<IconPlus color={themeColors.brandGray[0]} />}
            onClick={() =>
              form?.insertListItem(`${sectionFormKey}`, {
                Position: { Name: '' },
                Director: ''
              })
            }
            data-cy="addPositionBtn"
            disabled={disabled}
          >
            {t('profile.addPosition')}
          </Button>
        </SimpleGrid>
      )}

      <SelectUserModal
        multipleUsers={false}
        isOpened={selectOwnerModal}
        title={t('profile.addManager')}
        onClose={closeSelectOwnerModal}
        setSelectedUsers={handleChangePositionParent}
        userId={selectedUser.Id}
      />
    </>
  );
};

const defaultError = (field: string) => `Введите ${field}`;

export const validateSchemePositions = z.array(
  z.object({
    Position: z
      .string()
      .min(1, { message: defaultError('должность') })
      .optional()
      .or(z.literal('')),
    ParentUserId: z
      .number({
        invalid_type_error: defaultError('руководитель')
      })
      .positive({ message: defaultError('отдел') })
      .optional()
      .or(z.literal(0)),
    ParentUserName: z
      .string()
      .min(1, { message: defaultError('должность') })
      .optional()
      .or(z.literal(''))
  })
);
