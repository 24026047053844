import { FC, ReactNode } from 'react';
import { ActionIcon, Group, Modal as BaseModal, ModalProps, ScrollArea } from '@mantine/core';
import { useStyles } from './styles';
import { ArrowLeftIcon } from '@assets/icons';

export interface IModalProps extends ModalProps {
  title?: ReactNode;
  onClose: () => void;
  onBackClick?: false | (() => void);
}

export const Modal: FC<IModalProps> = ({
  title,
  children,
  onClose,
  onBackClick: onBack,
  ...props
}) => {
  const { classes } = useStyles();

  const renderTitle = () => {
    if (!onBack) {
      return title;
    }
    return (
      <Group gap="sm">
        <ActionIcon onClick={onBack}>
          <ArrowLeftIcon />
        </ActionIcon>
        {title}
      </Group>
    );
  };

  return (
    <BaseModal
      radius="md"
      padding={0}
      zIndex={1400}
      title={renderTitle()}
      classNames={{ ...classes }}
      className={classes.scrollbar}
      onClose={onClose}
      scrollAreaComponent={ScrollArea.Autosize}
      {...props}
    >
      {children}
    </BaseModal>
  );
};
