import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  input: {
    '& .mantine-TextInput-input': {
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px'
    },
    '& .mantine-TextInput-wrapper': {
      '&[data-error=true]': {
        '& .mantine-Select-section': {
          color: '#868e96'
        }
      }
    },
    '& .mantine-InputWrapper-label': {
      color: '#4B4B4B',
      fontSize: theme.fontSizes.sm,
      fontWeight: 500
    }
  },

  rightBox: {
    fontSize: '14px',
    color: 'black'
  },

  disabledBox: {
    color: '#868e96',
    cursor: 'not-allowed'
  },

  domainSelect: {
    '& .mantine-Select-wrapper': {
      borderWidth: 0,
      margin: 0
    },
    '& .mantine-Select-section': {
      marginRight: '4px'
    },
    '& .mantine-Select-input': {
      borderRight: 'none',
      cursor: 'pointer',
      borderLeft: 'none',
      borderRadius: '8px',
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
      '&:focus': {
        borderColor: '#d0d5dd',
        outline: 'none'
      },
      '&[data-disabled="true"]': {
        cursor: 'not-allowed',
        backgroundColor: 'transparent'
      }
    }
  },

  changeInputContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '5px',
    cursor: 'pointer'
  },

  changeInput: {
    fontSize: 12,
    color: theme.colors.blue[6]
  },

  refreshIcon: {
    color: theme.colors.blue[6],
    marginRight: '5px'
  },

  inputLabel: {
    '& .mantine-InputWrapper-label': {
      color: '#4B4B4B',
      fontSize: theme.fontSizes.sm,
      fontWeight: 500
    }
  },

  statusSelect: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      borderRadius: '8px'
    },
    '& .mantine-InputWrapper-label': {
      color: '#4B4B4B',
      fontSize: theme.fontSizes.sm,
      fontWeight: 500
    }
  }
}));
