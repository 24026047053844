import { createContext, Dispatch, FC, SetStateAction, useEffect } from 'react';

import { BaseInfo, DrawerHeader, PhotoInfo } from '../components';

import { IRowData } from '@components/simple-table/types';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';

import { useGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';
import { Flex, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface IFilialsInfoMainContext {
  selectedFilialsId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  filialsInfoCommon?: IFilialsInfoCommon;
}

export const FilialsInfoMainContext = createContext<IFilialsInfoMainContext>(
  {} as IFilialsInfoMainContext
);

export interface IPanelsProps {
  selectedFilialsId: number;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  handleClose: () => void;
}

export const Panels: FC<IPanelsProps> = ({ selectedFilialsId, setRowData, handleClose }) => {
  const { data, isLoading, isError } = useGetFilialsInfoCommonQuery({
    id: selectedFilialsId
  });
  const { t } = useTranslation();

  const initialValues: IFilialsInfoMainContext = {
    selectedFilialsId,
    setRowData,
    filialsInfoCommon: data
  };

  useEffect(() => {
    if (isError) {
      notifications.show({
        title: t('requestsErrors.error'),
        message: t('filials.filialNotFound'),
        color: 'red'
      });
      handleClose();
    }
  }, [isError]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" h="100vh">
          <Loader />
        </Flex>
      ) : (
        <FilialsInfoMainContext.Provider value={initialValues}>
          <DrawerHeader />
          <PhotoInfo filialsInfoCommon={data} />
          <BaseInfo />
        </FilialsInfoMainContext.Provider>
      )}
    </>
  );
};
