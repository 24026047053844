import { Button, Flex, Loader, Stack, Text } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconCertificate } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useGetUserTariffQuery } from '@/entities/admin-app/user/api';
import { ExpiredPayment } from '../../expiredPayment';
import { ValidPayment } from '../../validPayment';
import { FC } from 'react';
import { useAppSelector } from '@/hooks/redux/redux';
import { ModuleUrl } from '@/entities/admin-app/auth';
import { Link } from 'react-router-dom';

interface IPaymentSectionProps {
  filialId?: number;
  subscriptionPage?: boolean;
  paymentsDate: string | null;
}

export const PaymentSection: FC<IPaymentSectionProps> = ({
  filialId,
  subscriptionPage,
  paymentsDate
}) => {
  const { t } = useTranslation();

  // let needToDelayFetch = false;
  // if (paymentsDate) {
  //   const actionDate = new Date(paymentsDate);
  //   // Do your operations
  //   const currentDate = new Date();
  //   const secondsDiff = (currentDate.getTime() - actionDate.getTime()) / 1000;
  //   if (secondsDiff < 3) needToDelayFetch = true;
  // }
  // needToDelayFetch = true;

  const { data, isLoading } = useGetUserTariffQuery({ id: filialId ?? 0, paymentsDate });

  const modules = useAppSelector(
    (state) => state.userInfo.Settings?.ModuleSettings?.ModuleUrls
  ) as ModuleUrl[];

  const paymentModule = Array.isArray(modules)
    ? modules.find((module) => module.Name === 'Payments')
    : undefined;
  const OplataLink = paymentModule?.Url;

  const isPayedTariff = () => {
    return data && data?.Tariff?.Type !== -1;
  };

  const isExpiredPayment = () => {
    //change later expired = status === 0
    const tariffDate = data?.Date ? new Date(data?.Date) : new Date();
    return tariffDate <= new Date();
  };

  const getUserCount = () => {
    if (!data?.UserCounter?.PossibleAmount) return 0;
    return `${data?.UserCounter?.CurrentAmount} / ${data?.UserCounter?.PossibleAmount}`;
  };

  const calculateDaysRemaining = (expiringDate?: string) => {
    if (!expiringDate) return 0;
    const currentDate = new Date();
    const futureDate = new Date(expiringDate);
    const timeDifference = futureDate.getTime() - currentDate.getTime();

    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysRemaining;
  };

  const remainingDays = calculateDaysRemaining(data?.Date);

  return (
    <CardContainer
      sectionName={t('filials.payments')}
      Icon={IconCertificate}
      subscriptionPage={subscriptionPage}
    >
      {isLoading ? (
        <Flex w="100%" align="center" justify="center">
          <Loader size="sm" />
        </Flex>
      ) : (
        <>
          {isExpiredPayment() ? (
            <ExpiredPayment usedSpace={data?.DiskCounter.CurrentAmount} link={OplataLink} />
          ) : (
            <>
              <ValidPayment name={data?.Name} remainingDays={remainingDays} />
              <Stack gap="4px">
                <Flex justify="space-between">
                  <Text fz="sm" c="#848E98">
                    {t('filials.diskSpace.validity')}
                  </Text>
                  <Text fz="sm" fw="500">
                    {isPayedTariff()
                      ? `${
                          data?.Date
                            ? t('filials.diskSpace.to') +
                              ' ' +
                              new Date(data?.Date).toLocaleDateString('ru-RU')
                            : ''
                        }`
                      : t('filials.diskSpace.unlimited')}
                  </Text>
                </Flex>
                <Flex justify="space-between">
                  <Text fz="sm" c="#848E98">
                    {t('filials.diskSpace.usersNumber')}
                  </Text>
                  <Text fz="sm" fw="500">
                    {isPayedTariff() ? getUserCount() : data?.UserCounter?.CurrentAmount || 1}
                  </Text>
                </Flex>
              </Stack>
            </>
          )}
          <Button
            w="100%"
            mt="16px"
            fw="500"
            component={Link}
            target={!subscriptionPage ? '_blank' : '_self'}
            to={OplataLink ?? '#'}
            radius="4px"
          >
            {remainingDays > 30 ? t('change') : t('filials.diskSpace.activateTariff')}
          </Button>
        </>
      )}
    </CardContainer>
  );
};
