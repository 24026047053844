import { Box, Text, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../styles';
import { DriveInfoPanelsEnum } from '@/types/enums/modules';

import { Radio } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAppSelector } from '@/hooks/redux/redux';
import { QuotaRulesEnum } from '@/types/enums';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useEffect } from 'react';

export interface ICalculateQuota {
  rule: QuotaRulesEnum;
}

export const CalculateQuota = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { filial } = useAppSelector((state) => state.filials);
  const saveSettings = useSaveSettings();

  const form = useForm<ICalculateQuota>({
    initialValues: {
      rule: QuotaRulesEnum.None
    }
  });

  useEffect(() => {
    const { QuotaRules } = filial?.Settings || {};

    if (!QuotaRules) {
      return;
    }

    const formData = {
      rule: QuotaRules
    };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleChange = (selectedValue: QuotaRulesEnum) => {
    form.setFieldValue('rule', selectedValue);
  };

  const handleSubmit = () => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.QuotaRules, {
      QuotaRules: form.values.rule
    });
    form.resetDirty();
  };

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  return (
    <Box className={classes.section} data-section={DriveInfoPanelsEnum.quotas}>
      <Text className={classes.title}>{t('filials.diskSpace.calculateQuotaTitle')}</Text>
      <Radio
        className={classes.radio}
        size="xs"
        checked={form?.values?.rule === QuotaRulesEnum.Large}
        onChange={() => handleChange(QuotaRulesEnum.Large)}
        label={t('filials.diskSpace.bigQuota')}
      />
      <Radio
        className={classes.radio}
        size="xs"
        checked={form?.values?.rule === QuotaRulesEnum.Lower}
        onChange={() => handleChange(QuotaRulesEnum.Lower)}
        label={t('filials.diskSpace.smallQuota')}
      />
      <Radio
        className={classes.radio}
        size="xs"
        checked={form?.values?.rule === QuotaRulesEnum.Summary}
        onChange={() => handleChange(QuotaRulesEnum.Summary)}
        label={t('filials.diskSpace.sumQuota')}
      />
      <Text fz="14" c="#667085" mt="10px">
        {t('filials.diskSpace.calculateQuotaInfo')}
      </Text>

      <Button
        size="sm"
        mt="sm"
        radius={8}
        px="40px"
        type="submit"
        disabled={isDisabled}
        onClick={() => {
          handleSubmit();
        }}
      >
        {t('save')}
      </Button>
    </Box>
  );
};
