import { useGetDefaultQuotaRelationFiltersQuery } from '@/entities/admin-app/quotas/api';
import { IDefaultFilter } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const transformFilters = (data: IDefaultFilter[], t: (key: string) => string) => {
  return data
    ?.map((item) => ({
      ...item,
      label:
        item.Type === IFiltersEnumType.QuotaFilter
          ? t('filials.diskSpace.quotasSetup.filters.quota')
          : item.Type === IFiltersEnumType.UserFilter
          ? t('filials.diskSpace.quotasSetup.filters.owner')
          : item.Type === IFiltersEnumType.ObjectRoleFilter
          ? t('filials.diskSpace.quotasSetup.filters.objectType')
          : t('filters.title'),
      Items: item.Items?.map((filterItem) => ({
        ...filterItem,
        Count: undefined,
        checked: false
      })),
      hideSearch: false
    }))
    .sort((a, b) => a.Type - b.Type);
};

export const useGetFilters = ({ customerId }: { customerId: number }) => {
  const { t } = useTranslation();

  const { data, isFetching, isSuccess } = useGetDefaultQuotaRelationFiltersQuery(
    {
      count: 20,
      customerId
    },
    {
      skip: !customerId
    }
  );

  const filters = useMemo(() => {
    return isSuccess ? transformFilters(data || [], t) : [];
  }, [data, isSuccess, t]);

  return { filters, isFetching };
};
