import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IconDotsVertical, IconEye } from '@tabler/icons';
import { actionMenu, ActionUserMenuEnum } from '@/mock-data/user-table';
import { IRowData } from '@components/simple-table/types';
import { ActionIcon, Group, Text } from '@mantine/core';
import { PopoverMenu } from '@components/popover-menu';
import { useDisclosure } from '@mantine/hooks';
import { useChangeStatuses } from '@hooks/users/useChangeStatuses';
import { RoleTypesEnum, UserStatusEnum } from '@/types/enums/user';
import { useTerminateSessions } from '../../../hooks/useTerminateSessions';
import { SelectUserModal } from '@components/modal/select-user';
import { IUserTableData } from '@/entities/admin-app/users';
import { DeleteUser } from '@/containers/pages/users/components/modals/delete';
import { useActions } from '@hooks/redux/action';
import { useAddRoles } from '@hooks/users/useAddRoles';
import { SelectGroupModal } from '@components/modal/select-group';
import { IGroup } from '@/entities/admin-app/user';
import { IListItem } from '@/types';
import { useNavigate } from 'react-router-dom';
import { LoginAsModalContent } from '@/containers/pages/users/components/modals/login-as';
import { useAppSelector } from '@hooks/redux/redux';
import { OneUserActivationModal } from '@/containers/pages/users/components/modals/send-activation/oneUserActivation';
import { useTranslation } from 'react-i18next';
import { SendMailModal } from '../../modals/send-email/send-modal';
import { Modal } from '@/components/modal';
import { VerifyEmailAndOpenModal } from '@/containers/pages/filials/hooks/useVerifyEmailAndOpenModal';
import { EditProfileIcon } from '@/assets/icons';
import { IRole } from '@/entities/admin-app/directories';

interface IActionProps {
  item?: IRowData;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  hasIconEye?: boolean;
  scrollRef?: any;
  loginAsUser?: string | null;
}

export const Action: FC<IActionProps> = ({
  item,
  setRowData,
  hasIconEye = true,
  scrollRef,
  loginAsUser
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const User = useAppSelector((state) => state?.userInfo?.User);
  const { users } = useAppSelector((state: any) => state.users);
  const { handleCheckCustomerServerSettings } = VerifyEmailAndOpenModal();
  const [opened, { close, open }] = useDisclosure(false);
  const { setSelectedUserId } = useActions();
  const [selectOwnerModal, setSelectOwnerModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUserTableData>({} as IUserTableData);
  const [openDeleteUsersModal, setOpenDeleteUsersModal] = useState(false);
  const [openAddRolesModal, setOpenAddRolesModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [openConfirmEmailModal, setOpenConfirmEmailModal] = useState(false);
  const [openLoginAsModal, setOpenLoginAsModal] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<IListItem>({} as IListItem);
  const [groups, setGroups] = useState<IRole[]>([]);
  const { handleAddRoles } = useAddRoles([item?.Id], setRowData);
  const { changeStatuses } = useChangeStatuses(setRowData);
  const { closeSessions } = useTerminateSessions();

  const closeAddRolesModal = () => {
    setOpenAddRolesModal(false);
  };

  const handleOpenAddRolesModal = () => {
    setOpenAddRolesModal(true);
  };

  const handleOpenDeleteUsersModal = () => {
    setOpenDeleteUsersModal(true);
  };

  const handleCloseDeleteUsersModal = () => {
    setOpenDeleteUsersModal(false);
  };

  const handleAddRole = (roles: IGroup[]) => {
    const rolesIds = roles?.map((role) => role.Id);
    handleAddRoles([item?.Id], rolesIds);
    setOpenAddRolesModal(false);
  };

  const goEditUser = (id: number, event?: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/profile/${id}`);
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmEmailModal(true);
  };

  const handleOpenSendEmailModal = () => {
    setOpenSendEmailModal(true);
  };
  const onClickItem = useCallback(
    async (event: React.SyntheticEvent<HTMLDivElement>) => {
      close();
      event.preventDefault();
      event.stopPropagation();
      const value = event.currentTarget.dataset.value ?? '';

      switch (value) {
        case ActionUserMenuEnum.loginAs:
          setOpenLoginAsModal(true);

          break;
        case ActionUserMenuEnum.activateEmail:
          await handleCheckCustomerServerSettings(item?.Customer?.Id, handleOpenConfirmModal);
          break;
        case ActionUserMenuEnum.block:
          await changeStatuses([item?.Id], UserStatusEnum.Ban);
          break;
        case ActionUserMenuEnum.addRole:
          handleOpenAddRolesModal();
          break;
        case ActionUserMenuEnum.writeLetter:
          await handleCheckCustomerServerSettings(item?.Customer?.Id, handleOpenSendEmailModal);
          break;
        case ActionUserMenuEnum.editUser:
          goEditUser(item?.Id);
          break;
        case ActionUserMenuEnum.unblock:
          await changeStatuses([item?.Id], UserStatusEnum.Active);
          break;
        case ActionUserMenuEnum.terminateAll:
          await closeSessions({ userId: item?.Id || null });
          break;
        case ActionUserMenuEnum.deleteUser:
          handleOpenDeleteUsersModal();
          break;
        case ActionUserMenuEnum.deletePersonalData:
          break;
        case ActionUserMenuEnum.transferData:
          break;
        default:
          break;
      }
    },
    [item]
  );

  const handleClickShowInfo = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.nativeEvent.stopPropagation();

      setSelectedUserId(item?.Id);
    },
    [item]
  );
  const handleContextMenu = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    close();
  };

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    open();
  };

  const closeSelectOwnerModal = () => {
    setSelectOwnerModal(false);
  };

  const isEmailConfirmed = () => {
    if (hasIconEye) {
      return item?.IsConfirmed;
    } else {
      return item?.Credentials?.IsConfirmed;
    }
  };

  useEffect(() => {
    const selectedUser = users.find((user: IUserTableData) => user?.Id === item?.Id);
    setGroups(selectedUser?.Roles || []);
  }, [item?.Id, users]);

  const showLoginAs = () => {
    if (hasIconEye) {
      return (
        item?.Id !== User?.Id &&
        !item?.RoleTypes?.some(
          (roleType: number) =>
            roleType === RoleTypesEnum.Admin || roleType === RoleTypesEnum.SuperUser
        )
      );
    } else {
      return (
        item?.Id !== User?.Id &&
        !item?.Roles?.some(
          (role: any) =>
            role?.Type === RoleTypesEnum.Admin || role?.Type === RoleTypesEnum.SuperUser
        )
      );
    }
  };

  const showDelete = () => {
    if (hasIconEye) {
      return (
        item?.Id !== User?.Id &&
        !item?.RoleTypes?.some((roleType: number) => roleType === RoleTypesEnum.SuperUser)
      );
    } else {
      return (
        item?.Id !== User?.Id &&
        !item?.Roles?.some((role: any) => role?.Type === RoleTypesEnum.SuperUser)
      );
    }
  };
  const showChangeStatus = () => {
    const isLoggedAsSuperUser = User?.Roles.some((role) => role.Type === RoleTypesEnum.SuperUser);

    if (hasIconEye) {
      const hasNoSuperUserRole = !item?.RoleTypes?.some(
        (roleType: number) => roleType === RoleTypesEnum.SuperUser
      );
      return item?.Id !== User?.Id && (isLoggedAsSuperUser || hasNoSuperUserRole);
    } else {
      const hasNoSuperUserRole = !item?.Roles?.some(
        (role: any) => role?.Type === RoleTypesEnum.SuperUser
      );

      return item?.Id !== User?.Id && (isLoggedAsSuperUser || hasNoSuperUserRole);
    }
  };

  let popoverList = actionMenu(item?.Status, {
    showDeleteItem: showDelete(),
    showLoginAsItem: showLoginAs(),
    showChangeStatusItem: showChangeStatus()
  });

  if (hasIconEye) {
    popoverList = popoverList.filter((item) => item.value !== ActionUserMenuEnum.terminateAll);
  }

  return (
    <>
      <Group gap={4} justify="flex-end" wrap="nowrap">
        <ActionIcon
          className="action-icon"
          data-action="action"
          onClick={(event) => goEditUser(item?.Id, event)}
          data-cy="editProfile"
        >
          <EditProfileIcon size={24} />
        </ActionIcon>
        {hasIconEye && (
          <ActionIcon
            className="action-icon"
            onClick={handleClickShowInfo}
            data-action="action"
            data-cy="viewDetails"
          >
            <IconEye size="1.5rem" color="#667085" stroke={1.5} style={{ pointerEvents: 'none' }} />
          </ActionIcon>
        )}
        <PopoverMenu
          opened={opened}
          overflow
          onClickItem={onClickItem}
          list={popoverList}
          position="left-start"
          onClose={close}
          email={item?.Email}
          isConfirmed={isEmailConfirmed()}
        >
          <ActionIcon
            className="action-icon"
            data-action="action"
            onClick={handleClick}
            onContextMenu={handleContextMenu}
            data-cy="moreActions"
          >
            <IconDotsVertical color="#667085" data-action="action" />
          </ActionIcon>
        </PopoverMenu>
      </Group>
      <SelectUserModal
        multipleUsers={false}
        isOpened={selectOwnerModal}
        title={t('users.addGroupOwner')}
        onClose={closeSelectOwnerModal}
        setSelectedUsers={setSelectedUser}
        userId={selectedUser.Id}
        controlSection
      />
      {openDeleteUsersModal && (
        <DeleteUser
          isOpened={openDeleteUsersModal}
          selectedUsers={[item?.Id]}
          handleClose={handleCloseDeleteUsersModal}
          setRowData={setRowData}
          scrollRef={scrollRef}
        />
      )}
      {openAddRolesModal && (
        <SelectGroupModal
          isOpened={openAddRolesModal}
          title={t('profile.addRole')}
          onClose={closeAddRolesModal}
          setSelectedGroup={setSelectedRoles}
          selectedGroup={selectedRoles}
          setGroups={handleAddRole}
          groups={groups}
        />
      )}
      {openSendEmailModal && (
        <Modal
          size="500px"
          zIndex={1400}
          opened={openSendEmailModal}
          onClose={() => setOpenSendEmailModal(false)}
          title={t('sendLetter.newLetter')}
        >
          <SendMailModal setOpenModal={setOpenSendEmailModal} selection={[item?.Id]} />
        </Modal>
      )}
      {openConfirmEmailModal && (
        <OneUserActivationModal
          open={openConfirmEmailModal}
          handleClose={() => setOpenConfirmEmailModal(false)}
          selectedUsers={[item?.Id]}
          email={item?.Email}
        />
      )}
      <Modal
        withinPortal={true}
        opened={openLoginAsModal}
        onClose={() => setOpenLoginAsModal(false)}
        title={<Text fz="md">{t('profile.loginAs')}</Text>}
      >
        <LoginAsModalContent
          setOpen={setOpenLoginAsModal}
          loginAs={loginAsUser ?? item?.Login}
          login={User?.Login}
        />
      </Modal>
    </>
  );
};
