import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Drawer } from '@mantine/core';
import { useStyles } from './styles';
import { IRowData } from '@components/simple-table/types';
import { Panels } from './panels';
import { PanelsEnum } from '@/types/enums/user';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions } from '@/hooks/redux/action';
import { useAppSelector } from '@/hooks/redux/redux';

interface IUserInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const UserInfoMain: FC<IUserInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const { setSelectedUserId } = useActions();
  const { selectedUserId } = useAppSelector((state) => state.users);
  const { id } = useParams();
  const toNumberId = useMemo(() => Number(id), [id]);
  useEffect(() => {
    if (toNumberId) {
      setSelectedUserId(toNumberId);
      setIsOpened(true);
    } else {
      handleClose();
    }
  }, [toNumberId]);

  const [activePanel, setActivePanel] = useState<PanelsEnum>(PanelsEnum.generalInformation);

  const handleClose = useCallback(() => {
    setIsOpened(false);
    setSelectedUserId(0);
    navigate('/users');
    setActivePanel(PanelsEnum.generalInformation);
  }, []);

  return (
    <Drawer
      zIndex={1100}
      opened={isOpened}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {selectedUserId ? (
        <Panels
          activePanel={activePanel}
          setActivePanel={setActivePanel}
          selectedUserId={selectedUserId}
          setRowData={setRowData}
          handleClose={handleClose}
        />
      ) : null}
    </Drawer>
  );
};
