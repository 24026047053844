import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles(() => ({
  itemName: {
    fontSize: '14px',
    color: '#848E98'
  },

  interactiveItemName: {
    cursor: 'pointer',
    '&:hover': {
      color: '#339AF0',
      textDecoration: 'underline'
    }
  },

  badgeRoot: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    borderRadius: '16px',
    padding: `0px 6px`,
    backgroundColor: '#D0EBFF'
  },

  badgeInner: {
    color: '#228BE6',
    fontSize: '10px',
    fontWeight: 500
  }
}));
