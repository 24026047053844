import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useStyles } from '../styles';
import { Flex, Text, Box } from '@mantine/core';
import { SimpleGrid } from '@mantine/core';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { SelectQuotas } from '@/containers/pages/user/components/Quotaselect';
import { Roles } from '@/containers/pages/user/components/sections/roles';
import { Positions } from '@/containers/pages/user/components/sections/positions';

interface IMoreInfoProps {
  disabled: boolean;
}

export const MoreInfo = ({ disabled }: IMoreInfoProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);

  const { Customer, Quota, Roles: userRoles, Positions: userPositions } = form.values;
  const CustomerFormKey = 'Customer';
  const QuotasFormKey = 'Quota';

  return (
    <Flex direction="column" w="100%">
      <Box mb="30px">
        <Text mb="16px" className={classes.sectionTitle}>
          {t('profile.company')}
        </Text>
        <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%">
          <SelectWithPagination
            list={[]}
            label={t('unit')}
            placeholder={t('choose')}
            fieldName={`${CustomerFormKey}.Id`}
            form={form}
            defaultValue={Customer?.Id || null}
            value={Customer?.Id}
            isCreateUser
            className={classes.selectLabel}
            customerField={`${CustomerFormKey}.Name`}
            filialName={Customer?.Name}
            disabled={disabled}
          />
          {/* {form.getInputProps(`${CustomerFormKey}.Customer.Id`).error} */}
          <SelectQuotas
            form={form}
            fieldName={`${QuotasFormKey}.Id`}
            quotaField={`${QuotasFormKey}.Name`}
            Customer={Customer?.Id}
            value={Quota?.Id}
            QuotaName={Quota?.Name}
            className={classes.selectLabel}
            createUser
            disabled={disabled}
          />
        </SimpleGrid>
      </Box>
      <Box mb="30px">
        <Text mb="16px" className={classes.sectionTitle}>
          {t('profile.roles')}
        </Text>
        <Roles Roles={userRoles} fieldName="Roles" form={form} disabled={disabled} />
      </Box>
      <Box>
        <Text className={classes.sectionTitle}>{t('profile.position')}</Text>
        <Positions
          Positions={userPositions}
          form={form}
          sectionFormKey="Positions"
          disabled={disabled}
        />
      </Box>
    </Flex>
  );
};
