import { Flex, Loader, Stack } from '@mantine/core';
import { CardContainer } from '../../cardContainer';
import { IconDatabase } from '@tabler/icons';
import { IListItemProps, ListItem } from '../../listItem';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ProgressBar } from '../../progressBar';
import { useGetFilialQuotaQuery } from '@/entities/admin-app/quotas/api';

interface IFilialSectionProps {
  filialId?: number;
  subscriptionPage?: boolean;
}

export const DiskSpaceSection: FC<IFilialSectionProps> = ({ filialId, subscriptionPage }) => {
  const { t } = useTranslation();
  const { data: quota, isLoading } = useGetFilialQuotaQuery(filialId ? filialId : 0);

  const diskSpaceMenu: IListItemProps = {
    withCheck: false,
    items: [
      {
        name: t('filials.diskSpace.diskConfig'),
        link: `${ROUTES.filials.edit.clearPath}/${filialId}/${ROUTES.filials.edit.pages.common.diskSpace}`
      },
      {
        name: t('navigation.statistics'),
        link: `${ROUTES.statistics.fullPath}/${ROUTES.statistics.links.diskSpace}`
      }
    ]
  };

  return (
    <CardContainer
      sectionName={t('statistics.disk_space')}
      Icon={IconDatabase}
      subscriptionPage={subscriptionPage}
    >
      {isLoading ? (
        <Flex w="100%" align="center" justify="center">
          <Loader size="sm" />
        </Flex>
      ) : (
        <>
          <ProgressBar quotaDetails={quota} />
          <Stack gap="11px" sx={{ width: '100%' }}>
            <ListItem items={diskSpaceMenu.items} withCheck={diskSpaceMenu.withCheck} />
          </Stack>
        </>
      )}
    </CardContainer>
  );
};
