import { useContext } from 'react';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { Box, Flex } from '@mantine/core';
import { AvatarFio } from './avatar-fio';
import { LoginInfo } from './login-info';
import { AdditionalInfo } from './additional-info';
import { Control } from './control';
import { useStyles } from './styles';

interface ISectionsProps {
  isLoading: boolean;
  modalClose: () => void;
  isPasswordEnabled: boolean;
  setIsPasswordEnabled: (value: boolean) => void;
  previewAvatar: string;
  setPreviewAvatar: (value: string) => void;
}

export const Sections = ({
  isLoading,
  modalClose,
  isPasswordEnabled,
  setIsPasswordEnabled,
  previewAvatar,
  setPreviewAvatar
}: ISectionsProps) => {
  const { form } = useContext(CreateUserContext);
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.modalContainer} sx={{ paddingBottom: '100px' }}>
        <Flex direction="column" gap="20px" w="100%">
          <AvatarFio preview={previewAvatar} setPreview={setPreviewAvatar} disabled={isLoading} />
          <LoginInfo disabled={isLoading} />
          <AdditionalInfo
            disabled={isLoading}
            isPasswordEnabled={isPasswordEnabled}
            setIsPasswordEnabled={setIsPasswordEnabled}
          />
        </Flex>
      </Box>
      <Box
        className={classes.modalFooter}
        sx={{
          position: 'fixed',
          bottom: '0',
          zIndex: 1,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <Control form={form} isLoading={isLoading} modalClose={modalClose} />
      </Box>
    </>
  );
};
