import { Button, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { UseFormReturnType } from '@mantine/form';
import { IUserInfo } from '@/entities/admin-app/user';
import { useTranslation } from 'react-i18next';
import { useConfirmCancelForm } from '@/containers/pages/user/feature/create-user/hooks/useConfirmCancelForm';

interface IControlProps {
  form: UseFormReturnType<IUserInfo>;
  isLoading: boolean;
  modalClose: () => void;
}
export const Control = ({ form, isLoading, modalClose }: IControlProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const errors = form.errors;

  const isDisabled = Object.keys(errors).length !== 0;

  const handleCancelForm = useConfirmCancelForm(modalClose);

  return (
    <>
      <Button
        size="sm"
        radius={8}
        type="submit"
        mr={16}
        disabled={isDisabled}
        loading={isLoading}
        data-cy="createUser"
        w="49%"
      >
        {t('create')}
      </Button>

      <UnstyledButton
        onClick={handleCancelForm}
        data-cy="cancelCreateUser"
        className={classes.groupButton}
        w="49%"
      >
        {t('cancel')}
      </UnstyledButton>
    </>
  );
};
