import { EditUserInfoContext } from '@/containers/pages';
import { useAppSelector } from '@/hooks/redux/redux';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import TimezoneForm from './form';

const ProfileTimeZones = () => {
  const { Settings } = useAppSelector((state) => state.userInfo);
  const Timezone = Settings?.Timezone;
  const { userInfoCommon, currentId } = useContext(EditUserInfoContext);
  const myUserId = useAppSelector((state) => state.userInfo.User?.Id);
  const { id } = useParams();

  const isCurrentUser = myUserId === Number(id);

  return (
    <>
      {Settings && userInfoCommon ? (
        <TimezoneForm
          isCurrentUser={isCurrentUser}
          userInfoCommon={userInfoCommon}
          Timezone={Timezone}
          currentId={currentId}
          Settings={Settings}
        />
      ) : null}
    </>
  );
};

export default ProfileTimeZones;
