import React, { useRef, useState } from 'react';
import { TextProps, Text, Tooltip } from '@mantine/core';

interface OverflowTipProps extends TextProps {
  children: string;
  maxWidth?: string;
  index?: number;
}

const OverflowTip = ({ children, maxWidth = '200px', index, ...props }: OverflowTipProps) => {
  const [opened, setOpened] = useState<boolean>(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  const handleHover = () => {
    const element = textElementRef.current;
    if (element) {
      const elementWidth = element.offsetWidth;
      const scrollWidth = element?.scrollWidth; // Use optional chaining
      setOpened(scrollWidth > elementWidth);
    }
  };

  return (
    <Tooltip
      withArrow
      label={children}
      multiline
      maw={maxWidth}
      sx={{
        wordBreak: 'break-word'
      }}
      transitionProps={{ transition: 'pop', duration: 300 }}
      arrowSize={6}
      offset={0}
      opened={opened}
      zIndex={index}
    >
      <Text
        onMouseEnter={handleHover}
        onMouseLeave={() => setOpened(false)}
        {...props}
        data-text={children || ''}
        ref={textElementRef}
        truncate={true}
        w="100%"
      >
        {children}
      </Text>
    </Tooltip>
  );
};

export default React.memo(OverflowTip);
