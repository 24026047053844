import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';

import { Flex, Loader, MantineProvider, DirectionProvider } from '@mantine/core';
import { MainRoutes } from './containers/routes';
import { theme } from './theme/global-theme';
import '@/theme/global.css';
import { Notifications } from '@mantine/notifications';
import AppVersion from '../src/preval';
import { AppFeature, isFeature } from '@utils/feature';
import { ModalProvider } from '@components/modal/modal-provider';
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion';
import { useEffect, useState } from 'react';
import { useLazyCurrentUserQuery, useSettingsQuery } from '@/entities/admin-app/user/api';
import { store } from '@/store';
import { userInfoActions } from '@/entities/admin-app/user';
import { IGlobalSettings, IUser } from '@/entities/admin-app/auth';
import { CustomFonts } from './theme/fonts';
import * as Sentry from '@sentry/react';
import { redirectIfAuth } from '@utils/authRedirect';
import { ErrorBoundary } from '@components/error-boundary';
import { useTranslation } from 'react-i18next';

import { useLazyGetTimezonesQuery } from './entities/admin-app/commonSettings/api';
import { useActions } from './hooks/redux/action';

if (process.env.NODE_ENV === 'production' && import.meta.env.VITE_UPLOAD_TO_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://c082040c3b9f33dc9d8fd7fb82dc5032@o489084.ingest.sentry.io/4506344158724096',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/admin\.r7-space\.ru/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

(window as any).appVersion = AppVersion;
(window as any).appFeature = AppFeature;

const App = () => {
  const { i18n } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: dataSetting, status: statusSettings } = useSettingsQuery();
  const [fetchUserInfo, { data: dataUser, status: statusUser }] = useLazyCurrentUserQuery();
  const isAuthorized = Number(dataSetting?.UserId || -1) > 0;
  const localLanguage = localStorage.getItem('user-language');
  const [fetchTimezones, { data: dataTimezones, status: statusTimezones }] =
    useLazyGetTimezonesQuery();
  const { setTimeZones } = useActions();

  useEffect(() => {
    if (dataTimezones?.length) {
      setTimeZones(dataTimezones);
    }
  }, [dataTimezones]);

  useEffect(() => {
    if (dataSetting) {
      // i18n.changeLanguage(dataSetting?.Language?.Name || 'ru');
      store.dispatch(userInfoActions.setGlobalSettings(dataSetting as any as IGlobalSettings));
    }
  }, [dataSetting]);

  useEffect(() => {
    if (isAuthorized) {
      redirectIfAuth();
      fetchUserInfo();
      fetchTimezones();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (dataUser) {
      store.dispatch(userInfoActions.setUserInfo(dataUser as any as IUser));
    }
  }, [dataUser]);

  useEffect(() => {
    if (
      (isAuthorized && statusUser === 'fulfilled') ||
      statusUser === 'rejected' ||
      (!isAuthorized && statusSettings === 'fulfilled') ||
      statusSettings === 'rejected' ||
      (isAuthorized && statusTimezones === 'fulfilled') ||
      statusTimezones === 'rejected'
    ) {
      setIsLoaded(true);
    }
  }, [statusSettings, statusUser]);

  useEffect(() => {
    // ToDo change logic when language will come from settings endpoint,now it based on localStorage
    if (isFeature() && localLanguage && localLanguage !== i18n.language) {
      const lang = JSON.parse(localLanguage) === 'ru' ? 'ru' : 'en';
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <DirectionProvider>
      <MantineProvider theme={theme} stylesTransform={emotionTransform}>
        <MantineEmotionProvider>
          <Notifications position="top-right" zIndex={1450} />
          <ErrorBoundary
            componentTitle="Settings"
            additionalError={statusSettings === 'rejected' || statusUser === 'rejected'}
          >
            <CustomFonts />
            {isLoaded ? (
              <ModalProvider>
                <MainRoutes />
              </ModalProvider>
            ) : (
              <Flex justify="center" align="center" w="100vw" h="100vh">
                <Loader />
              </Flex>
            )}
          </ErrorBoundary>
        </MantineEmotionProvider>
      </MantineProvider>
    </DirectionProvider>
  );
};

export default App;
