import { FC, useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { GenderEnum } from '@/types/enums';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface IDepartmentProps {
  defaultValue?: string;
  form: UseFormReturnType<any>;
  fieldName?: string;
  clearable?: boolean;
  disabled?: boolean;
}

export const Gender: FC<IDepartmentProps> = ({
  form,
  fieldName = 'Gender',
  clearable = false,
  disabled
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const list = [
    { label: t('male'), value: String(GenderEnum.male) },
    { label: t('female'), value: String(GenderEnum.female) }
  ];
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(Math.random());
  }, [form.values[fieldName]]);

  return (
    <Select
      comboboxProps={{ withinPortal: true, zIndex: 1400 }}
      data-cy="genderSelector"
      key={key}
      className={classes.gender}
      clearable={clearable}
      data={list}
      label={t('profile.gender')}
      placeholder={t('profile.choose')}
      disabled={disabled}
      value={
        form.getInputProps(fieldName).value === 0
          ? null
          : String(form.getInputProps(fieldName).value)
      }
      // eslint-disable-next-line
      // @ts-ignore
      onChange={(query: string) => {
        form?.setFieldValue(fieldName, Number(query));
      }}
      error={form.errors[fieldName]}
    />
  );
};
