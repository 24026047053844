import { useContext, useEffect } from 'react';
import { useStyles } from './styles';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { Flex, Radio, SimpleGrid, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '@mantine/core';

interface ICreatePassword {
  isPasswordEnabled: boolean;
  setIsPasswordEnabled: (value: boolean) => void;
  disabled: boolean;
}

export const CreatePassword = ({
  isPasswordEnabled,
  setIsPasswordEnabled,
  disabled
}: ICreatePassword) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { form } = useContext(CreateUserContext);

  useEffect(() => {
    if (!isPasswordEnabled) {
      form.clearFieldError('Credentials.Password');
      form.setFieldValue('Credentials.Password', null);
    }
  }, [isPasswordEnabled]);

  return (
    <Flex direction="column" mt="20px">
      <Text fz="14" fw="500" c="#4B4B4B" mb="xs">
        {t('login.password')}
      </Text>
      <Radio
        checked={!isPasswordEnabled}
        onChange={() => setIsPasswordEnabled(false)}
        className={classes.radio}
        label={t('profile.generatePassword')}
        data-cy="enableGeneratePassword"
      />
      <Radio
        checked={isPasswordEnabled}
        onChange={() => setIsPasswordEnabled(true)}
        className={classes.radio}
        label={t('profile.createPassword')}
        data-cy="enableCreatePassword"
      />
      <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
        {isPasswordEnabled && (
          <PasswordInput
            radius="md"
            mt="xs"
            className={classes.textFieldInput}
            placeholder={t('profile.password')}
            value={form.getInputProps('Credentials.Password').value}
            withAsterisk
            data-cy="passwordInput"
            {...form.getInputProps('Credentials.Password')}
            disabled={disabled}
          />
        )}
      </SimpleGrid>
    </Flex>
  );
};
