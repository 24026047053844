import { ErrorBoundary } from '../error-boundary';
import { Layout } from '@/containers/layout';
import { useAppSelector } from '@/hooks/redux/redux';
import { useMemo } from 'react';
import { NotFoundSection } from './components/not-found-section/NotFoundSection';

export function NotFound() {
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const isAuthenticated = useMemo(() => !!currentUser?.Id, [currentUser]);

  return (
    <ErrorBoundary componentTitle="404 page">
      {isAuthenticated ? (
        <Layout>
          <NotFoundSection />
        </Layout>
      ) : (
        <NotFoundSection />
      )}
    </ErrorBoundary>
  );
}
