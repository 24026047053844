import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { themeColors } from '@/theme/colors';
import { useActions } from '../redux/action';
import { PanelsEnum } from '@/types/enums/roles';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { useAppSelector } from '../redux/redux';
import { useTranslation } from 'react-i18next';
import { useDeleteQuotaRelationMutation } from '@/entities/admin-app/quotas/api';

export const useDeleteQuotas = () => {
  const { t } = useTranslation();
  const [deleteQuota, { isSuccess }] = useDeleteQuotaRelationMutation();
  const { setSelectedPanel } = useActions();
  const { quotasFilters } = useAppSelector((state) => state.quotas);
  const { setQuotasFilters } = useActions();

  const handleConfirm = async (selection: any) => {
    try {
      const res = await deleteQuota(selection);
      if ('error' in res) {
        const codeError = extractErrorCode(res.error);
        showNotification({
          title: generateErrorMessage(codeError),
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err) {
      console.log('fetchDeleteGroup', err);
    }
  };

  const openDeleteQuotas = (selection: any) => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: <Box mb={24}>{t('quota.deleteWarningText')}</Box>,
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%',
            '& .mantine-Button-label': {
              color: 'black'
            }
          }
        },
        labels: { confirm: t('continue'), cancel: t('cancel') },
        onConfirm: () => handleConfirm(selection)
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setQuotasFilters({
        ...quotasFilters,
        Offset: 0
      });

      showNotification({
        title: t('quota.deleteSuccess'),
        message: '',
        autoClose: true,
        color: 'green'
      });

      // if (setActivePanel) {
      setSelectedPanel(PanelsEnum.generalInformation);
      // }
    }
  }, [isSuccess]);

  return { openDeleteQuotas };
};
