import { useTranslation } from 'react-i18next';
import { CardContainer } from '../../cardContainer';
import { IconExclamationCircle, IconWaveSawTool } from '@tabler/icons';
import { ItemProps, ListItem } from '../../listItem';
import { Box, Text, Stack, Flex } from '@mantine/core';
import { useStyles } from './styles';
import { FC, useEffect, useState } from 'react';
import { useGetServicesQuery } from '@/entities/admin-app/user/api';
import { HealthServiceType } from '@/entities/admin-app/user';
import { Skeleton } from '@mantine/core';

interface IServicesSectionProps {
  filialId?: number;
}

export const ServicesSection: FC<IServicesSectionProps> = ({ filialId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data, isLoading, isError } = useGetServicesQuery(filialId ?? 0);
  const [servicesList, setServicesList] = useState<ItemProps[]>([]);

  useEffect(() => {
    if (data) {
      const transformedArray = data.map((item) => {
        const serviceName = t(`filials.services.${HealthServiceType[item.Type]}`);
        return {
          name: item.Name ? `${serviceName} ${item.Name}` : serviceName,
          valid: item.Health
        };
      });
      setServicesList(transformedArray);
    }
  }, [data]);

  return (
    <CardContainer sectionName={t('filials.availableServices')} Icon={IconWaveSawTool}>
      {isLoading ? (
        <Box w="100%">
          {Array(8)
            .fill(0)
            .map((_, index) => (
              <Flex justify="space-between" align="center" mb="xs" key={index}>
                <Skeleton height={20} width="70%" radius="xl" />
                <Skeleton height={20} width={20} circle />
              </Flex>
            ))}
        </Box>
      ) : isError ? (
        <Box className={classes.errorContainer}>
          <IconExclamationCircle size="26" stroke="#848E98" />
          <Text className={classes.errorText}>{t('avatar.error')}</Text>
        </Box>
      ) : (
        <Stack gap="7px" sx={{ width: '100%' }} mt="16px">
          <ListItem items={servicesList} withCheck={true} />
        </Stack>
      )}
    </CardContainer>
  );
};
