import React, { createContext, Dispatch, FC, SetStateAction, useEffect } from 'react';
import { HomePanel } from './home';
import { MoreInfoPanel } from './more-info';
import { ActiveConnectionsPanel } from './active-connections';
import { DataStorageQuotaPanel } from './data-storage-quota';
import { PanelsEnum } from '@/types/enums/user';
import { useGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { IRowData } from '@components/simple-table/types';
import { IUserInfo } from '@/entities/admin-app/user';
import { Flex, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface IUserInfoMainContext {
  selectedUserId: number;
  setActivePanel: Dispatch<SetStateAction<PanelsEnum>>;
  activePanel: PanelsEnum;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  userInfoCommon?: IUserInfo;
}

export const UserInfoMainContext = createContext<IUserInfoMainContext>({} as IUserInfoMainContext);

export interface IPanelsProps {
  selectedUserId: number;
  setActivePanel: Dispatch<SetStateAction<PanelsEnum>>;
  activePanel: PanelsEnum;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  handleClose: () => void;
}

export const Panels: FC<IPanelsProps> = ({
  selectedUserId,
  setActivePanel,
  activePanel,
  setRowData,
  handleClose
}) => {
  const { data, isLoading, isError } = useGetUserInfoQuery(selectedUserId);
  const { t } = useTranslation();
  const initialValues: IUserInfoMainContext = {
    selectedUserId,
    setActivePanel,
    activePanel,
    setRowData,
    userInfoCommon: data
  };

  useEffect(() => {
    if (isError) {
      notifications.show({
        title: t('requestsErrors.error'),
        message: t('users.userNotFound'),
        color: 'red'
      });
      handleClose();
    }
  }, [isError]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" h="100vh">
          <Loader />
        </Flex>
      ) : (
        <UserInfoMainContext.Provider value={initialValues}>
          {activePanel === PanelsEnum.generalInformation && <HomePanel />}
          {activePanel === PanelsEnum.additionalInformation && <MoreInfoPanel />}
          {/*{activePanel === PanelsEnum.groups && <GroupsPanel />}*/}
          {/*{activePanel === PanelsEnum.notes && <NotesPanel />}*/}
          {/*{activePanel === PanelsEnum.subscriptions && <SubscriptionsPanel />}*/}
          {activePanel === PanelsEnum.activeConnections && <ActiveConnectionsPanel />}
          {activePanel === PanelsEnum.dataStorageQuota && <DataStorageQuotaPanel />}
        </UserInfoMainContext.Provider>
      )}
    </>
  );
};
