import { createStyles } from '@mantine/emotion';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme, _, utils) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  accountName: {
    marginBottom: '16px'
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%'
  },
  addBtn: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    marginBottom: 'auto',
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },
  changePasswordButton: {
    backgroundColor: theme.colors.backgroundGray[6],
    borderRadius: '8px',
    border: `1px solid ${theme.colors.backgroundGray[6]}`,
    marginBottom: 'auto',
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-label': {
      color: theme.black
    },
    marginTop: '0!important' as any
  },

  listRow: {
    position: 'relative',
    alignItems: 'center',

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },
    '@media (min-width: 576px)': {
      gridTemplateColumns: '1fr 1fr 60px',
      width: '100%'
    },
    '@media (max-width: 576px)': {
      width: '100%',
      gap: '8px'
    }
  },
  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    marginBottom: '4px',
    border: 'none',
    textTransform: 'inherit',
    // height: '28px',
    fontSize: '14px',
    padding: theme.spacing.sm,
    fontWeight: 400,
    marginTop: '24px',
    height: '2.25rem'
  },

  removeButton: {
    marginBottom: '2px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& .mantine-ActionIcon-root': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      alignSelf: 'center'
    },
    '& button:hover': {
      backgroundColor: 'transparent'
    },
    [utils.smallerThan('xs')]: {
      position: 'absolute',
      right: '5px',
      top: '1px'
    }
  },

  title: {
    fontWeight: 600
  },

  textFieldInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      fontWeight: 400
    },

    '& .mantine-TextInput-input': {
      borderRadius: '4px',
      border: '1px solid #D0D5DD'
    }
  },

  createContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },

  controlsButtons: {
    justifyContent: 'space-between',
    [utils.smallerThan('xs')]: {
      flexDirection: 'column',
      'button:first-of-type': {
        margin: '0 0 15px 0'
      }
    }
  },

  dropdown: {
    display: 'none'
  },

  select: {
    '& .mantine-Select-input': {
      cursor: 'default',
      '&:focus': {
        borderColor: '#D0D5DD'
      }
    }
  }
}));
