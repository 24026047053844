import { Container } from '@mantine/core';
import { ScrollContainer } from '../../../scroll-container';
import { NoDataContainer } from '../noDataContainer/noDataContainer';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import Icon404 from '@/assets/noData/404.svg';

export const NotFoundSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const goMainPage = () => navigate('/');

  return (
    <ScrollContainer sx={{ height: '100%', width: '100%' }} type="auto">
      <Container className={classes.root}>
        <NoDataContainer
          title={t('notFound.somethingWrong')}
          description={t('notFound.404')}
          illustration={Icon404}
          buttons={[
            {
              buttonText: t('backMain'),
              handleClick: () => goMainPage()
            }
          ]}
        />
      </Container>
    </ScrollContainer>
  );
};
