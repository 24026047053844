import { HEADER_HEIGHT } from '@/components/header/constants';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme, _, utils) => ({
  paginateWrapper: {
    overflow: 'auto'
  },
  wrapper: {
    flexGrow: 1,
    overflow: 'auto',
    marginBottom: theme.spacing.md,
    [utils.smallerThan('lg')]: {
      paddingRight: theme.spacing.md
    },
    [utils.smallerThan('md')]: {
      padding: `0 ${theme.spacing.md}`
    }
  },

  container: {
    position: 'relative',
    overflowX: 'hidden'
  },

  searchContainer: {
    backgroundColor: theme.colors.backgroundGray[0],
    '.mantine-Input-wrapper': {
      // width: '100%'
    }
  },

  search: {
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    },
    '& .mantine-Input-section': {
      marginLeft: '15px'
    }
  },

  contentContainer: {
    backgroundColor: theme.white,
    borderRadius: '8px'
  },

  divider: {
    borderColor: theme.colors.backgroundGray[2]
  },

  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    overflowX: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    backgroundColor: 'white',
    [utils.smallerThan('md')]: {
      flexWrap: 'wrap'
    }
  },

  paginateControl: {
    border: 'none',
    borderRadius: '0px',
    padding: '23px 28px',
    [utils.smallerThan('md')]: {
      padding: '10px 15px'
    },
    '&[data-active]': {
      borderBottom: '2px solid #101828',
      backgroundColor: theme.white,
      color: theme.black,
      '&:hover': {
        backgroundColor: theme.white
      }
    },
    '&[data-active]:not([data-disabled]):hover': {
      backgroundColor: theme.white
    }
  },

  paginateRoot: {
    backgroundColor: theme.white,
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'nowrap',
    padding: '23px 28px',
    '& .mantine-Group-root': {
      justifyContent: 'center'
    }
  },

  simpleNavbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    position: 'fixed',
    [utils.smallerThan('md')]: {
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      marginLeft: theme.spacing.xs,
      backgroundColor: 'white',
      padding: '0'
    }
  },

  headerMenu: {
    flexGrow: 1,
    overflow: 'auto',
    [utils.smallerThan('md')]: {
      maxWidth: '850px',
      backgroundColor: 'white',
      borderRadius: '8px',
      margin: '20px 15px'
    },
    [utils.largerThan('md')]: {
      marginLeft: 300
    }
  },

  headerMenuBurger: {
    padding: '20px 0 0 10px',
    button: {
      div: {
        height: '2px'
      }
    },
    [utils.largerThan('md')]: {
      display: 'none'
    }
  },

  section: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  },

  link: {
    color: theme.colors.blue[5],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
