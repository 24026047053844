import { createStyles } from '@mantine/emotion';

export interface IStatusParams {
  blocked?: boolean;
}

export const useStyles = createStyles((theme, { blocked }: IStatusParams, utils) => ({
  selector: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      borderRadius: '20px',
      width: '170px',
      border: '1px solid #D0D5DD',
      backgroundColor: blocked ? 'rgba(194, 57, 57, 0.2)' : 'rgba(23, 92, 211, 0.2)',
      [utils.largerThan('xs')]: {
        width: '200px'
      }
    }
  },

  blue: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      color: '#175CD3'
    }
  },
  red: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      color: '#DE3639'
    }
  }
}));
