import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useStyles } from '../styles';
import { Select, ComboboxItem, Box } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';

interface ICreateEmailProps {
  domains: ComboboxItem[];
  setIsValidEmail: (value: boolean) => void;
  disabled?: boolean;
}

export const CreateEmail = ({ domains, setIsValidEmail, disabled }: ICreateEmailProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);

  const [selectedDomain, setSelectedDomain] = useState(domains.length > 0 ? domains[0].value : '');

  const CredentialsFormKey = 'Credentials';

  useEffect(() => {
    if (domains.length > 0) {
      setSelectedDomain(domains[0].value);
    }
  }, [domains]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailPrefix = event.target.value;

    if (emailPrefix.length === 0) {
      form.setFieldValue(`${CredentialsFormKey}.Email`, '');
      setIsValidEmail(true);
    } else {
      if (!emailPrefix.includes('@')) {
        form.setFieldValue(`${CredentialsFormKey}.Email`, `${emailPrefix}${selectedDomain}`);
      }
    }
  };

  const handleDomainChange = (value: string | null) => {
    if (value) {
      setSelectedDomain(value);
      const emailPrefix = form.getInputProps(`${CredentialsFormKey}.Email`).value.split('@')[0];
      if (emailPrefix) {
        form.setFieldValue(`${CredentialsFormKey}.Email`, `${emailPrefix}${value}`);
      }
    }
  };

  const rightSection =
    domains.length <= 1 ? (
      <Box
        className={cx(classes.rightBox, {
          [classes.disabledBox]: disabled
        })}
      >
        {selectedDomain}
      </Box>
    ) : (
      <Select
        data={domains}
        w="100%"
        rightSectionWidth={20}
        comboboxProps={{ zIndex: 1300 }}
        rightSection={<IconChevronDown size="1rem" />}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        maxDropdownHeight={150}
        allowDeselect={false}
        data-cy="selectDomain"
        disabled={disabled}
        className={classes.domainSelect}
        value={selectedDomain}
        onChange={handleDomainChange}
        px={0}
      />
    );

  return (
    <ValidateTextInput
      fieldName={`${CredentialsFormKey}.Email`}
      label={t('profile.email')}
      form={form}
      className={classes.input}
      setIsValidEmail={setIsValidEmail}
      description={t('profile.createEmailDescription')}
      rightSection={rightSection}
      rightSectionWidth={domains.length <= 1 ? selectedDomain.length * 8 + 10 : 120}
      onChange={handleInputChange}
      createEmail
      selectedDomain={selectedDomain}
      dataAttribute="createEmail"
      isRequired={form.values.Credentials.isSaas}
      disabled={disabled}
    />
  );
};
