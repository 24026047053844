import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserContext } from '@/entities/context/createUserContext';
import { useStyles } from './styles';
import { Flex, Text, ComboboxItem } from '@mantine/core';
import { SimpleGrid } from '@mantine/core';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';
import { CreateEmail } from './create-email';
import { useLazyEmailServerDomainsQuery } from '@/entities/admin-app/email/api';
import { IServerDomains } from '@/entities/admin-app/email';
import { IconRefresh } from '@tabler/icons';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { TextFieldInput } from '@/components/fields/text-field-input';
import { StatusSelect } from '@/containers/pages/user/components/StatusSelect';
import { useMediaQuery } from '@mantine/hooks';

interface ILoginInfoProps {
  disabled: boolean;
}

export const LoginInfo = ({ disabled }: ILoginInfoProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { form } = useContext(CreateUserContext);
  const { isCreateEmail } = form.values.Credentials;
  const { Customer, Status, Credentials } = form.values;
  const CredentialsFormKey = 'Credentials';
  const isMobile = useMediaQuery('(max-width: 576px)');

  const [servers, setServers] = useState<IServerDomains[]>([]);
  const [getDomainsData, { data: domainsData }] = useLazyEmailServerDomainsQuery();

  const [isLoginTouched, setIsLoginTouched] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    if (Customer?.Id) {
      getDomainsData(Customer.Id);
    }
  }, [Customer?.Id]);

  useEffect(() => {
    if (domainsData) {
      setServers(domainsData);
    }
  }, [domainsData]);

  const list = useMemo(
    () =>
      Array.isArray(servers)
        ? servers.map(
            (Item: any, index: number) =>
              ({
                key: index,
                label: `@${String(Item?.Domain)}`,
                value: `@${String(Item?.Domain)}`
              } as ComboboxItem)
          )
        : [],
    [servers]
  );

  const handleToggle = () => {
    form.setFieldValue(`${CredentialsFormKey}.isCreateEmail`, !isCreateEmail);
    form.setFieldValue(`${CredentialsFormKey}.Email`, '');
    form.clearFieldError(`${CredentialsFormKey}.Email`);
  };

  useEffect(() => {
    if (isValidEmail && !isLoginTouched) {
      form.setFieldValue(`${CredentialsFormKey}.Login`, Credentials.Email);
    }
    if ((!isValidEmail || !form.isValid(`${CredentialsFormKey}.Email`)) && !isLoginTouched) {
      form.setFieldValue(`${CredentialsFormKey}.Login`, '');
      setIsValidEmail(false);
    }
  }, [isValidEmail, Credentials.Email]);

  const handleLoginChange = () => {
    setIsLoginTouched(true);
  };

  useEffect(() => {
    if (domainsData) {
      if (form.values.Credentials.isCreateEmail && !servers.length) {
        form.setFieldValue(`${CredentialsFormKey}.isCreateEmail`, false);
        form.setFieldValue(`${CredentialsFormKey}.Email`, '');
      }
    }
  }, [servers]);

  return (
    <Flex direction="column" w="100%">
      <SimpleGrid cols={{ base: 1, xs: 2 }} w="100%" spacing={isMobile ? 0 : 'md'}>
        <Flex direction="column">
          {isCreateEmail ? (
            <>
              <CreateEmail domains={list} setIsValidEmail={setIsValidEmail} disabled={disabled} />
              <Flex className={classes.changeInputContainer}>
                <IconRefresh size={11} className={classes.refreshIcon} />
                <Text onClick={handleToggle} className={classes.changeInput}>
                  {t('profile.useExistingEmail')}
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <ValidateTextInput
                rightSectionWidth={30}
                fieldName={`${CredentialsFormKey}.Email`}
                label={t('login.login')}
                form={form}
                description={t('profile.emailExistsDescription')}
                className={classes.inputLabel}
                setIsValidEmail={setIsValidEmail}
                isRequired={form.values.Credentials.isSaas}
                disabled={disabled}
              />
              {servers.length > 0 ? (
                <Flex className={classes.changeInputContainer}>
                  <IconRefresh size={11} className={classes.refreshIcon} />
                  <Text onClick={handleToggle} className={classes.changeInput}>
                    {t('profile.createNewEmail')}
                  </Text>
                </Flex>
              ) : null}
            </>
          )}
        </Flex>

        {isSaaS(Settings) ? (
          <TextFieldInput
            form={form}
            fieldName={`${CredentialsFormKey}.AdditionalEmail`}
            label={t('profile.backupEmail')}
            className={classes.inputLabel}
            description={t('profile.backupEmailDescription')}
            disabled={disabled}
          />
        ) : (
          <ValidateTextInput
            fieldName={`${CredentialsFormKey}.Login`}
            label={t('profile.login')}
            form={form}
            description={t('profile.loginDescription')}
            className={classes.inputLabel}
            handleLoginChange={handleLoginChange}
            withAsterisk
            disabled={disabled}
          />
        )}
      </SimpleGrid>

      {!isSaaS(Settings) && (
        <SimpleGrid mt="10px" cols={{ base: 1, xs: 2 }} w="100%" spacing={isMobile ? 0 : 'md'}>
          <>
            <TextFieldInput
              form={form}
              fieldName={`${CredentialsFormKey}.AdditionalEmail`}
              label={t('profile.backupEmail')}
              className={classes.inputLabel}
              description={t('profile.backupEmailDescription')}
              disabled={disabled}
            />
            <StatusSelect
              label={t('users.status')}
              placeholder={t('profile.chooseStatus')}
              fieldName="Status"
              form={form}
              value={Status}
              className={classes.statusSelect}
              description={t('profile.statusDescription')}
              disabled={disabled}
            />
          </>
        </SimpleGrid>
      )}
    </Flex>
  );
};
