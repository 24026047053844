import { /* SyntheticEvent, useCallback, */ useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useCallback } from 'react';
import { ErrorBoundary } from '@components/error-boundary';
import { SimpleTable } from '@components/simple-table';
import { IRowData } from '@components/simple-table/types';
import { useGetFilialsTreeQuery } from '@/entities/admin-app/filials/api';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';

import { Layout } from '../../layout';
import { FilialsInfoMain } from './components/main-info';

import { useGetColumns } from './hooks/useGetColumns';
import { useStyles } from './styles';

import { CreateFilial } from './components/create-item/index';

import { FilialSearch } from './components/search';
import { isSaaS } from '@/lib/utils/access';
import { ROUTES } from '@/containers/routes/constants';

export const Filials = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setFilials, setFilialsTableFilters /*, setSelectedFilialsIds, setSelectedFilialsId */ } =
    useActions();

  const {
    filials,
    total,
    filters: filialsFilter,
    selected: selectedFilials
  } = useAppSelector((state) => state.filials);
  const { Settings } = useAppSelector((state) => state.userInfo);

  const scrollRef = useRef<any>(null);

  const [rowData, setRowData] = useState<IRowData[]>(filials);

  const { data, error, isLoading, isFetching } = useGetFilialsTreeQuery({
    Count: 20,
    Offset: filialsFilter.Offset,
    Query: filialsFilter.Query
  });

  /*const handleSelected = (event: SyntheticEvent<HTMLInputElement>) => {
    const id = Number(event.currentTarget.dataset.id);
    if (id) {
      setSelectedFilialsIds(id);
    }
  };*/

  const { columns } = useGetColumns();

  const handleRowClick = useCallback((id: number) => {
    navigate(`${ROUTES.filials.path}/${id}`);
  }, []);

  const resetFilialsFilters = () => {
    setFilialsTableFilters({
      ...filialsFilter,
      Offset: 0,
      Query: ''
    });
  };

  const handleLoadMore = () => {
    const newOffset = filialsFilter.Offset + 20;
    if (newOffset < total) {
      setFilialsTableFilters({
        ...filialsFilter,
        Offset: newOffset
      });
    }
  };

  useEffect(() => {
    if (data) {
      const { Offset: offset } = filialsFilter;
      if (!offset) {
        setFilials(data);
      } else {
        const filialsData = filials.slice(0, offset).concat(data.Items);
        setFilials({ ...data, Items: filialsData });
      }
    }
  }, [data]);

  useEffect(() => {
    setRowData(filials || []);
  }, [filials]);

  useEffect(() => {
    return () => {
      resetFilialsFilters();
    };
  }, []);

  return (
    <ErrorBoundary componentTitle="Filials page">
      <Layout>
        <Box className={classes.container}>
          <Box className={classes.searchWrapper}>
            <FilialSearch />
            {!isSaaS(Settings) ? <CreateFilial /> : null}
          </Box>

          <SimpleTable
            dataCy="filialsTable"
            allDataLength={total}
            rowData={rowData}
            setRowData={setRowData}
            columns={columns}
            height="calc(100vh - 200px)" //  move height to const
            minWidth={700}
            width="calc(100vw - 48px)"
            selection={selectedFilials}
            handleRowClick={handleRowClick}
            getNextData={handleLoadMore}
            error={error}
            scrollRef={scrollRef}
            isLoading={isLoading}
            isFetching={isFetching}
            isExpanded
          />
        </Box>
        <Outlet />
        <FilialsInfoMain setRowData={setRowData} />
      </Layout>
    </ErrorBoundary>
  );
};
