import React, { FC, useContext } from 'react';
import { RolesInfoMainContext } from '..';
import { PanelsEnum } from '@/types/enums/roles';
import { HeaderPanel } from '../../components/panel-header';
import { EditRolesItem } from '../../components/edite-item';
import { useTranslation } from 'react-i18next';

export const MoreInfoPanel: FC = () => {
  const { t } = useTranslation();
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);

  return (
    <>
      <HeaderPanel title={t('roles.edit')} activePanel={PanelsEnum.generalInformation} />
      <EditRolesItem editedItem={rolesInfoCommon} />
    </>
  );
};
