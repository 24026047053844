import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer } from '@mantine/core';

import { IRowData } from '@components/simple-table/types';
import { Panels } from './panels';

import { useAppSelector } from '@hooks/redux/redux';
import { useActions } from '@hooks/redux/action';

import { useStyles } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@/containers/routes/constants';

interface IFilialsInfoMainProps {
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const FilialsInfoMain: FC<IFilialsInfoMainProps> = ({ setRowData }) => {
  const { classes } = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const { setSelectedFilialsId } = useActions();
  const { selectedFilialsId } = useAppSelector((state) => state.filials);
  const navigate = useNavigate();
  const { id } = useParams();
  const toNumberId = useMemo(() => Number(id), [id]);

  useEffect(() => {
    if (toNumberId) {
      setSelectedFilialsId(Number(toNumberId));
      setIsOpened(true);
    } else {
      handleClose();
    }
  }, [toNumberId]);

  const handleClose = useCallback(() => {
    setIsOpened(false);
    setSelectedFilialsId(0);
    navigate(ROUTES.filials.path);
  }, []);

  return (
    <Drawer
      data-cy="filialsInfoDrawer"
      zIndex={1002}
      opened={isOpened}
      trapFocus={false}
      onClose={handleClose}
      position="right"
      withCloseButton={false}
      className={classes.root}
      lockScroll
    >
      {Boolean(selectedFilialsId) && (
        <Panels
          selectedFilialsId={selectedFilialsId}
          setRowData={setRowData}
          handleClose={handleClose}
        />
      )}
    </Drawer>
  );
};
