import { Search } from '@/components/search/search-input';
import { Box, Button, Divider, Flex, LoadingOverlay, Pagination, Text } from '@mantine/core';

import { useStyles } from './styles';
import { SearchIcon } from '@/assets/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconPlus } from '@tabler/icons';
import { useGetQuotasRelationsQuery } from '@/entities/admin-app/quotas/api';
import { useAppSelector } from '@/hooks/redux/redux';
import { PaginatedTable } from '@/components/simple-table/PaginatedTable';
import { useGetColumns } from './hooks/useGetColumns';
import { NoDataText } from '@/components/not-found/components/no-data-text';
import { TableHeader } from '@/containers/pages/statistics/components/table-header';

import { listSizes } from '@/entities/admin-app/statistics/consts';
import { useGetFilters } from './hooks/useGetFilters';
import { FilterList } from './components/filter-list';
import { IChangeFilterData } from '@/types/api';
import { useActions } from '@/hooks/redux/action';
import { convertToFilterConditions } from './utils';
import { useGetUsersActionsFilterQuery } from '@/entities/admin-app/statistics/api';
import { useDebounce } from '@/hooks/useDebounce';
import { AssignQuotaModal } from '../assign-quota-modal';

type ActiveFilters = Record<number, number[] | Date[]>;

const pageSizeOptions = listSizes.map((page) => ({
  value: page.toString(),
  label: page.toString()
}));

const SetupQuota = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { columns, setColumns } = useGetColumns();

  const { setQuotaRelationFilters, setQuotaRelations } = useActions();
  const { filial } = useAppSelector((state) => state.filials);

  // Filters
  const { filters: filtersMenuList } = useGetFilters({ customerId: filial?.Id as number });
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({});
  const { quotaRelationFilters: filter, totalQuotaRelations } = useAppSelector(
    (state) => state.quotas
  );

  const handleFilterChange = ({ Type, value }: IChangeFilterData) => {
    const updatedData = { ...activeFilters };

    if (Array.isArray(value)) {
      updatedData[Type] = value;
    } else {
      if (typeof value === 'number') {
        const array = (updatedData[Type] as number[]) || [];
        updatedData[Type] = array.includes(value)
          ? array.filter((val) => val !== value)
          : [...array, value];
      }
    }

    setQuotaRelationFilters({
      ...filter,
      FilterConditions: convertToFilterConditions(updatedData),
      Offset: 0
    });
    setActiveFilters(updatedData);
  };

  const resetActiveFilterByType = (type: number) => {
    setActiveFilters((prevNumbers) => {
      const updatedNumbers = { ...prevNumbers };
      delete updatedNumbers[type];

      setQuotaRelationFilters({
        ...filter,
        FilterConditions: convertToFilterConditions(updatedNumbers),
        Offset: 0
      });

      return updatedNumbers;
    });
  };

  const resetAllActiveFilters = () => {
    setActiveFilters({});
    setQuotaRelationFilters({
      ...filter,
      FilterConditions: [],
      Offset: 0,
      Query: ''
    });
  };
  // --------------------

  // Search
  const [search, setSearch] = useState<string>('');
  const debounced = useDebounce(search, 500);

  useEffect(() => {
    setQuotaRelationFilters({
      ...filter,
      Query: debounced,
      Offset: 0
    });
  }, [debounced]);
  // --------------------

  // Pagination
  const [pageSize, setPageSize] = useState<number>(filter.Count || listSizes[0]);
  const [activePage, setActivePage] = useState(1);

  const handleChangeActivePage = (value: number) => {
    setActivePage(value);
    setQuotaRelationFilters({
      ...filter,
      Offset: (value >= 1 ? value - 1 : 0) * pageSize
    });
  };
  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setQuotaRelationFilters({
      ...filter,
      Offset: 0,
      Count: value
    });
  };
  // --------------------

  // Data
  const { data, isLoading } = useGetQuotasRelationsQuery(
    {
      CustomerId: filial?.Id as number,
      Count: pageSize,
      Offset: filter.Offset,
      Query: filter.Query,
      FilterConditions: filter.FilterConditions
    },
    {
      skip: !filial
    }
  );

  useEffect(() => {
    if (data) {
      setQuotaRelations(data);
    }
  }, [data]);
  // --------------------

  // Modals
  const { selectedQuotaRelation } = useAppSelector((state) => state.quotas);
  const { setSelectedQuotaRelation } = useActions();
  const [isAssignModalOpened, setIsAssignModalOpened] = useState(false);

  useEffect(() => {
    if (selectedQuotaRelation) {
      setIsAssignModalOpened(true);
    }
  }, [selectedQuotaRelation]);

  const handleAssignModalClose = () => {
    setSelectedQuotaRelation(null);
    setIsAssignModalOpened(false);
  };
  // --------------------

  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }

  return (
    <Box className={classes.wrapper}>
      <Flex direction="column" align="stretch" className={classes.container} mb="sm">
        <Flex gap={22} className={classes.searchContainer} align="center">
          <Search
            data-cy="auditSearch"
            icon={<SearchIcon />}
            setSearch={setSearch}
            className={classes.search}
            fullSize
            value={search}
            placeholder={t('filials.diskSpace.quotasSetup.searchPlaceholder')}
          />

          <Button
            leftSection={<IconPlus />}
            h={42}
            w={290}
            onClick={() => {
              setIsAssignModalOpened(true);
            }}
          >
            {t('filials.diskSpace.quotasSetup.createAssignment')}
          </Button>
        </Flex>
      </Flex>

      <Flex direction="column" align="stretch" className={classes.container}>
        <Flex direction="column" className={classes.contentContainer} px="md" py="md" mt="sm">
          <Text c="#101828" fw="600">
            {t('filials.diskSpace.quotasSetup.sectionTitle')}
          </Text>
          <Box className={classes.listWrapper}>
            <FilterList
              filtersMenuList={filtersMenuList}
              activeFilters={activeFilters}
              handleFilterChange={handleFilterChange}
              handleFilterReset={resetActiveFilterByType}
              handleResetFilters={resetAllActiveFilters}
              onLoadData={useGetUsersActionsFilterQuery}
            />
            {data?.Items?.length ? (
              <TableHeader
                handleChangePageSize={handleChangePageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                isLoading={isLoading}
                startOffset={filter.Offset + 1}
                endOffset={Math.min(filter.Offset + pageSize, data.TotalCount)}
                totalCount={data.TotalCount}
                showButton={true}
                buttonComponent={<div></div>}
                setColumnsData={setColumns}
                list={columns}
                // setColumnVisibility={setUsersActionsColumnVisibility}
              />
            ) : null}
          </Box>
          <Divider mb="sm" />
          {data?.Items?.length ? (
            <>
              <PaginatedTable
                allDataLength={data?.TotalCount}
                rowData={data?.Items}
                columns={columns}
                search={search}
                height="100%"
                width="100%"
                // selection={selectedUsers}
                // error={error}
                // scrollRef={scrollRef}
                isLoading={isLoading}
              />

              <Box className={classes.paginateWrapper}>
                {totalQuotaRelations > pageSize && (
                  <Pagination
                    classNames={{
                      root: classes.paginateRoot,
                      control: classes.paginateControl
                    }}
                    value={activePage}
                    onChange={handleChangeActivePage}
                    total={Math.ceil(totalQuotaRelations / pageSize)}
                    withControls={false}
                    withEdges
                    size="sm"
                  />
                )}
              </Box>
            </>
          ) : (
            <Flex align="center" justify="center" h="350px">
              <NoDataText />
            </Flex>
          )}
          <LoadingOverlay visible={false} />{' '}
        </Flex>
      </Flex>

      <AssignQuotaModal opened={isAssignModalOpened} handleClose={handleAssignModalClose} />
    </Box>
  );
};

export default SetupQuota;
